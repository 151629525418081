import './App.css';
import React, { useEffect, useState, useRef } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Amplify, API, graphqlOperation, Auth, I18n, Storage } from 'aws-amplify'
import { createDoctor, updateOrderNumber, updateAllOrders, updateRestorations } from './graphql/mutations'
import { createAllOrders } from './graphql/mutations'
import * as queries from './graphql/queries'
import * as mutations from './graphql/mutations'
import { useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";
import { yellow } from '@mui/material/colors';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import awsExports from "./aws-exports";
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import ReactToPrint from "react-to-print";
import ComponentToPrint from './ComponentToPrint';
import heic2any from "heic2any";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import RefreshIcon from '@mui/icons-material/Refresh';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Compressor from 'compressorjs';

// Set the log level before configuring Amplify
//Amplify.Logger.LOG_LEVEL = "DEBUG";

Amplify.configure(awsExports);

const isAdmin = ["mantas@me.com", "info@3dsmile.lt"]

const color = [
  " - ",
  "BL1",
  "BL2",
  "BL3",
  "BL4",
  "A1",
  "A2",
  "A3",
  "A3,5",
  "A4",
  "B1",
  "B2",
  "B3",
  "B4",
  "C1",
  "C2",
  "C3",
  "C4",
  "D2",
  "D3",
  "D4",
];

I18n.putVocabulariesForLanguage('en', {
  'Sign In': 'Prisijungti', // Tab header
  'Sign in': 'Prisijungti', // Button label
  'Sign in to your account': 'Welcome Back!',
  Username: 'Enter your username', // Username label
  Password: 'Slaptažodis', // Password label
  'Forgot your password?': 'Pamiršau slaptažodį',
});

const YellowButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(yellow[500]),
  backgroundColor: yellow[500],
  '&:hover': {
    backgroundColor: yellow[700],
  },
}));

const styles = {
  container: { width: '100%', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: { marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' },
  formControl: { backgroundColor: 'cyan', flexWrap: 'wrap', margin: '0 auto' },
  formControlLabel: { backgroundColor: 'green', flexWrap: 'wrap', margin: '0 auto' },
  bridgeCheckbox: { margin: '0 auto' },
  hover: { backgroundColor: 'lighblue' },
  tableHeaders: { fontWeight: 'bold', paddingTop: 3, paddingBottom: 3 },
  tableContent: { paddingTop: 4, paddingBottom: 4 },
}

const newDoctorState = { id: '', name: '', clinicID: '' }
const finalisedOrderDataState = {
  id: '',
  orderID: '',
  clinic: '',
  clinicName: '',
  doctor: '',
  patient: '',
  patientDOB: '',
  patientGender: '',
  patientOtherInfo: '',
  orderDate: '',
  orderReturnDate: '',
  teeth: '',
  teethName: '',
  restoration: '',
  implants: '',
  color: '',
  additionalInfo: '',
  orderState: '',
  expedited: '',
  fileList: '',
  fileListSTL: '',
  orderCorrectionDate: '',
  orderCorrectionReturnDate: '',
  orderCorrectionInformation: '',
  fileListOfCorrection: '',
  fileListSTLOfCorrection: '',
}

const MAX_FILE_COUNT = 20;
const MAX_FILE_COUNT_STL = 10;

const StyledTypography = styled(Typography)(({ theme }) => ({
  borderRadius: 4,
  border: '0px solid',
  borderColor: theme.palette.primary.main,
  maxHeight: '1.5rem',
  padding: 4,
  margin: 8
}));

const defaultStyling = {
  border: '0px solid gray'
}

const pageStyle = "@page { size: A5 }";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const themeOfMenu = createTheme({
  palette: {
    primary: {
      main: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
  },
});

let restorationInputHolder = []
let restorationsEnablingImplantsInputHolder = []
let implantsInputHolder = []

const Home = () => {

  const [user, setUser] = useState(null); // Store the authenticated user
  const [userEmail, setUserEmail] = useState(null); // Store the authenticated user email
  const [dataLoaded, setDataLoaded] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(""); // Store the selected month

  useEffect(() => {
    checkUser(); // Check user authentication status when the component mounts
  }, []);

  useEffect(() => {
    if (user) {
      // 'user' state is updated, so we can safely call loadData()
      setUserEmail(user.attributes.email)
    }
  }, [user]);

  useEffect(() => {
    if (userEmail) {
      // 'user' state is updated, so we can safely call loadData()
      loadData();
      setDataLoaded(true);
    }
  }, [userEmail]);

  useEffect(() => {
    // Set the default selected month to the current month
    const currentDate = new Date();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Format as MM

    setSelectedMonth(currentMonth);
  }, []);

  async function checkUser() {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      //console.log('Authenticated User:', authenticatedUser);
      setUser(authenticatedUser); // Set the user state if authenticated

    } catch (error) {
      setUser(null); // Set user state to null if not authenticated
      setUserEmail(null)
    }
  }

  async function handleSignOut() {
    try {
      await Auth.signOut({ global: true }); // Sign out the user
      setUser(null); // Clear the user state
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  // function userHere() {
  //   userEmail = Auth.user.attributes.email
  //   setTimeout(() => {
  //     if (Auth.user.attributes.email && Object.keys(stateUser).length == 0) {

  //       setLoadingAlertState(prevLOadingAlertState => ({
  //         ...loadingAlertState,
  //         loadingAlert: false
  //       }))

  //       setStateUser([...stateUser, userEmail]);
  //       getDoctors();
  //       getRestorations();
  //       getHistoricOrders();
  //     }
  //   }, 2000);
  // }

  async function loadData() {
    try {

      //addClinicNameAttribute();

      await getDoctors();
      await getRestorations();
      await getHistoricOrders();

      setDataLoaded(true);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }

  const componentRef = useRef();

  const [restoration, setCurrentRestorationValue] = useState([])
  const [restorationEnablingImplants, setCurrentRestorationEnablingImplantsValue] = useState([])
  const [implant, setCurrentImplantsValue] = useState([])

  const [currentRestorationValueVersion, setCurrentRestorationValueVersion] = useState('')
  const [currentRestorationEnablingImplantsValueVersion, setCurrentRestorationEnablingImplantsValueVersion] = useState('')
  const [currentImplantsValueVersion, setCurrentImplantsValueVersion] = useState('')

  const [clientFilter, setClientFilter] = useState('');
  const [clinicFilter, setClinicFilter] = useState('');

  const ImplantsList = () => {

    return (
      checkedTeeth.thisTeeth.length === 0 ? null :
        checkedTeeth.thisTeeth.map((number) => (
          number.includes('-') ?
            number.toString().split('-').map((singleTeeth, index) => (
              <div key={singleTeeth + index}>
                <Box sx={{ m: 2, display: 'flex', alignItems: 'center', ...defaultStyling }}>
                  <StyledTypography><b>{singleTeeth}:</b></StyledTypography>
                  <Autocomplete sx={{ width: 300 }}
                    options={implant}
                    id="implant"
                    value={implantName == null ? '' : implantName[singleTeeth]}
                    onChange={(i, newValue) => { implantOnChange(singleTeeth, newValue) }}
                    renderInput={(params) => (
                      <TextField {...params} label="Implantai" />
                    )}
                  />
                </Box>
              </div>
            ))
            :
            <div key={number}>
              <Box sx={{ m: 2, display: 'flex', alignItems: 'center', ...defaultStyling }}>
                <StyledTypography><b>{number}:</b></StyledTypography>
                <Autocomplete sx={{ width: 300 }}
                  options={implant}
                  id="implant"
                  value={implantName == null ? '' : implantName[number]}
                  onChange={(i, newValue) => { implantOnChange(number, newValue) }}
                  renderInput={(params) => (
                    <TextField {...params} label="Implantai" />
                  )}
                />
              </Box>
            </div>
        ))
    )
  }


  const [numberOfImplantComponents, setNumberOfImplantComponents] = useState([]);

  const [currentUsers, setCurrentUsers] = useState([])

  const [visible, setVisible] = useState(false);

  let nextToken;

  async function listUsers(limit) {
    let apiName = 'AdminQueries';
    let path = '/listUsers';
    let myInit = {
      queryStringParameters: {
        "limit": limit,
        "token": nextToken
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    }
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    nextToken = NextToken;

    const userList = rest.Users;
    const clientNamesAndEmails = [];

    userList.forEach((user) => {
      const emailAttribute = user.Attributes.find((attr) => attr.Name === 'email');
      const nameAttribute = user.Attributes.find((attr) => attr.Name === 'name');

      if (emailAttribute) {
        const email = emailAttribute.Value;
        const name = nameAttribute.Value;
        const nameAndEmail = { name: name, email: email }
        clientNamesAndEmails.push(nameAndEmail);
      }
    });

    setCurrentUsers(clientNamesAndEmails)

    return rest;
  }

  // const initialValueAheadState = dayjs(dayjs().add(7, 'day'))
  const initialValueAheadState = React.useState(null);
  const initialOrderCorrectionReturnDateState = dayjs(dayjs().add(7, 'day'))

  const [patientDOB, setPatientDOB] = React.useState(' ');
  const [valueAhead, setValueAhead] = React.useState(initialValueAheadState);
  const [orderCorrectionReturnDate, setOrderCorrectionReturnDate] = React.useState(initialOrderCorrectionReturnDateState);
  const [newDoctorValue, setNewDoctorValue] = useState('')

  const [open, setOpen] = React.useState(false);
  const [openOfDeleteOrder, setOpenOfDeleteOrder] = React.useState(false);

  const [doctors, setDoctors] = useState([])

  const [doctorChosen, setDoctor] = React.useState('');
  const [currentUserChosen, setCurrentUser] = React.useState('');

  const handleChange = (event) => {
    setDoctor(event.target.value)
    resetAlertState()
  };

  const handleChangeOfUser = (event) => {
    setCurrentUser(event.target.value)
    resetAlertState()
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAdminClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setNewDoctorValue('')
    setOpen(false);
  };

  const [selectedOrderItems, setSelectedOrderItems] = React.useState();

  // function handleClickOpenOfDeleteOrder(selectedItems) {
  //   setSelectedOrderItems(selectedItems);
  //   setOpenOfDeleteOrder(true);
  // };

  const handleClickOpenOfDeleteOrder = (selectedItems) => {
    setSelectedOrderItems(selectedItems);
    setOpenOfDeleteOrder(true);
  };

  const handleCloseOfDeleteOrder = () => {
    setOpenOfDeleteOrder(false);
  };

  const [patientName, setPatientName] = React.useState('');

  const handlePatientNameChange = (event) => {
    setPatientName(event.target.value)
    setAlertState(prevAlertState => ({
      ...alertState,
      patientNameAlert: false
    }))
  };

  const [patientGender, setPatientGender] = React.useState('female');
  const [orderVersion, setOrderVersion] = React.useState('');

  const handlePatientGenderChange = (event) => {
    setPatientGender(event.target.value)
  };

  const [patientAdditionalInfo, setPatientAdditionalInfo] = React.useState('');
  const [orderState, setOrderState] = React.useState('');

  const handlePatientAdditionalInfoChange = (event) => {
    setPatientAdditionalInfo(event.target.value)
  };

  const [filesToUploadList, setFilesToUploadList] = useState([])
  const [filesToUploadListSTL, setFilesToUploadListSTL] = useState([])
  const [filesToUploadListOfCorrection, setFilesToUploadListOfCorrection] = useState([])
  const [filesToUploadListSTLOfCorrection, setFilesToUploadListSTLOfCorrection] = useState([])

  const [fileLimit, setFileLimit] = useState(false)

  const [fileLimitSTL, setFileLimitSTL] = useState(false)

  const [errorAlertDialogOpen, setErrorAlertDialogOpen] = useState(false);
  const [errorDescription, setErrorDescription] = useState(""); // State for error description
  const [errorMessage, setErrorMessage] = useState(""); // State for error message


  const handleStageUploadFiles = files => {
    const uploaded = [];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        // console.log("Failai: ", uploaded);
        // console.log("Failai: ", uploaded.length);
        if (uploaded.length === MAX_FILE_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_FILE_COUNT) {
          alert(`Daugiausiai įkelti galima tik ${MAX_FILE_COUNT} failus`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    })
    if (!limitExceeded) {
      setFilesToUploadList(uploaded)
    }
  }

  const handleStageUploadFilesSTL = files => {
    const uploaded = [];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        //console.log("Failai: ", uploaded.length);
        if (uploaded.length === MAX_FILE_COUNT_STL) setFileLimitSTL(true);
        if (uploaded.length > MAX_FILE_COUNT_STL) {
          alert(`Daugiausiai įkelti galima tik ${MAX_FILE_COUNT_STL} failus`);
          setFileLimitSTL(false);
          limitExceeded = true;
          return true;
        }
      }
    })
    if (!limitExceeded) setFilesToUploadListSTL(uploaded)
  }

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    //console.log(chosenFiles)
    handleStageUploadFiles(chosenFiles);
    resetAlertState()
  }

  const handleFileEventSTL = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    handleStageUploadFilesSTL(chosenFiles);
  }

  const removeFile = (i) => {
    setFilesToUploadList([...filesToUploadList.filter((_, index) => index !== i)]);
  };

  const removeFileSTL = (i) => {
    setFilesToUploadListSTL([...filesToUploadListSTL.filter((_, index) => index !== i)]);
  };

  const listSelectedFiles = filesToUploadList.map((file, index) => <li key={index}>{file.name} <button variant="contained" color="error" size="small"
    onClick={() => {
      removeFile(index);
    }}
  >
    Trinti
  </button>
  </li>);

  const listSelectedFilesSTL = filesToUploadListSTL.map((file, index) => <li key={index}>{file.name} <button variant="contained" color="error" size="small"
    onClick={() => {
      removeFileSTL(index);
    }}
  >
    Trinti
  </button>
  </li>);

  // -------------- BEGIN Correction elements -----------------------

  const handleStageUploadFilesOfCorrection = files => {
    const uploaded = [];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        //console.log("Failai: ", uploaded.length);
        if (uploaded.length === MAX_FILE_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_FILE_COUNT) {
          alert(`Daugiausiai įkelti galima tik ${MAX_FILE_COUNT} failus`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    })
    if (!limitExceeded) {
      setFilesToUploadListOfCorrection(uploaded)
    }
  }

  const handleStageUploadFilesSTLOfCorrection = files => {
    const uploaded = [];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        //console.log("Failai: ", uploaded.length);
        if (uploaded.length === MAX_FILE_COUNT_STL) setFileLimitSTL(true);
        if (uploaded.length > MAX_FILE_COUNT_STL) {
          alert(`Daugiausiai įkelti galima tik ${MAX_FILE_COUNT_STL} failus`);
          setFileLimitSTL(false);
          limitExceeded = true;
          return true;
        }
      }
    })
    if (!limitExceeded) setFilesToUploadListSTLOfCorrection(uploaded)
  }


  const handleFileEventOfCorrection = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    handleStageUploadFilesOfCorrection(chosenFiles);
  }

  const handleFileEventSTLOfCorrection = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    handleStageUploadFilesSTLOfCorrection(chosenFiles);
  }

  const removeFileOfCorrection = (i) => {
    setFilesToUploadListOfCorrection([...filesToUploadListOfCorrection.filter((_, index) => index !== i)]);
  };

  const removeFileSTLOfCorrection = (i) => {
    setFilesToUploadListSTLOfCorrection([...filesToUploadListSTLOfCorrection.filter((_, index) => index !== i)]);
  };

  const listSelectedFilesOfCorrection = filesToUploadListOfCorrection.map((file, index) => <li key={index}>{file.name} <button variant="contained" color="error" size="small"
    onClick={() => {
      removeFileOfCorrection(index);
    }}
  >
    Trinti
  </button>
  </li>);

  const listSelectedFilesSTLOfCorrection = filesToUploadListSTLOfCorrection.map((file, index) => <li key={index}>{file.name} <button variant="contained" color="error" size="small"
    onClick={() => {
      removeFileSTLOfCorrection(index);
    }}
  >
    Trinti
  </button>
  </li>);

  // -------------- END Correction elements -----------------------

  async function getDoctors() {

    // Query with filters, limits, and pagination
    let clinicFilter = ''

    if (isAdmin.includes(userEmail)) {
      clinicFilter = {
        clinicID: {
          contains: "@"
        }
      };
    } else {
      clinicFilter = {
        clinicID: {
          contains: userEmail
        }
      };
    }

    try {
      const doctorsData = await API.graphql({
        query: queries.listDoctors,
        variables: { filter: clinicFilter }
      })

      const doctorsList = doctorsData.data.listDoctors.items

      setDoctors(doctorsList)
    } catch (error) {
      setErrorDescription("Failed to find doctors");
      setErrorMessage(error);
      setErrorAlertDialogOpen(true);
    }
  }

  async function getRestorations() {

    try {
      const restorationsData = await API.graphql({
        query: queries.listRestorations,
      })

      const restorationsList = restorationsData.data.listRestorations.items

      restorationsList.forEach((el, i) => {
        if (el?.id === "1") {
          let arr = el.name.split(",")
          setCurrentRestorationValue(arr)
          setCurrentRestorationValueVersion(el._version)
        }
        if (el?.id === "2") {
          let arr = el.name.split(",")
          setCurrentRestorationEnablingImplantsValue(arr)
          setCurrentRestorationEnablingImplantsValueVersion(el._version)
        }
        if (el?.id === "3") {
          let arr = el.name.split(",")
          setCurrentImplantsValue(arr)
          setCurrentImplantsValueVersion(el._version)
        }
      });

    } catch (error) {
      setErrorDescription("Failed to find restorations");
      setErrorMessage(error);
      setErrorAlertDialogOpen(true);
    }

  }

  const restorationInput = (event) => {
    restorationInputHolder = event.target.value.split("\n")
  };

  const restorationEnablingImplantsInput = (event) => {
    restorationsEnablingImplantsInputHolder = event.target.value.split("\n")
  };

  const implantsInput = (event) => {
    implantsInputHolder = event.target.value.split("\n")
  };

  async function addRestorations() {

    if (restorationInputHolder.length !== 0) {

      const input = { id: 1, name: (restorationInputHolder.toString()), _version: (currentRestorationValueVersion) }

      try {
        await API.graphql(graphqlOperation(updateRestorations, { input: input }))
        refreshPage()
      } catch (err) {
        setErrorDescription("Failed to create restorations");
        setErrorMessage(err);
        setErrorAlertDialogOpen(true);
      }
    } else {
      setErrorDescription("Restorations are empty!");
      setErrorMessage("");
      setErrorAlertDialogOpen(true);
    }
  }

  async function addRestorationsEnablingImplants() {

    if (restorationsEnablingImplantsInputHolder.length !== 0) {

      const input = { id: 2, name: (restorationsEnablingImplantsInputHolder.toString()), _version: (currentRestorationEnablingImplantsValueVersion) }

      try {
        await API.graphql(graphqlOperation(updateRestorations, { input: input }))
        refreshPage()
      } catch (err) {
        setErrorDescription("Failed to create restorations enabling implants");
        setErrorMessage(err);
        setErrorAlertDialogOpen(true);
      }
    } else {
      setErrorDescription("Restorations enabling implants are empty!");
      setErrorMessage("");
      setErrorAlertDialogOpen(true);
    }
  }

  async function addImplants() {

    if (implantsInputHolder.length !== 0) {

      const input = { id: 3, name: (implantsInputHolder.toString()), _version: (currentImplantsValueVersion) }

      try {
        await API.graphql(graphqlOperation(updateRestorations, { input: input }))
        refreshPage()
      } catch (err) {
        setErrorDescription("Failed to create implants");
        setErrorMessage(err);
        setErrorAlertDialogOpen(true);
      }
    } else {
      setErrorDescription("Implants are empty!");
      setErrorMessage("");
      setErrorAlertDialogOpen(true);
    }
  }

  async function updateOrderReturnDate(selectedOrderNumber, orderVersion, valueAhead) {

    const formattedDate = valueAhead && dayjs.isDayjs(valueAhead) ? valueAhead.format('DD/MM/YYYY') : '';

    const input = {
      id: selectedOrderNumber,
      orderReturnDate: formattedDate,
      _version: orderVersion
    };

    try {
      const updatedOrder = await API.graphql(graphqlOperation(updateAllOrders, { input: input }));

      refreshPage();
    } catch (err) {
      setErrorDescription("Failed updating order state");
      setErrorMessage(err);
      setErrorAlertDialogOpen(true);
    }
  }

  async function getHistoricOrders() {

    //console.log(Auth.currentAuthenticatedUser())

    let clinicFilter = ''

    if (isAdmin.includes(userEmail)) {
      clinicFilter = {
        clinic: {
          contains: "@"
        },
      };
    } else {
      clinicFilter = {
        clinic: {
          contains: userEmail
        }
      };
    }

    try {

      let allHistoricOrders = [];
      let nextToken = null;

      do {
        const historicOrdersResponse = await API.graphql({
          query: queries.listAllOrders,
          variables: {
            filter: clinicFilter,
            limit: 100, // Set the desired batch size
            nextToken: nextToken // Use the nextToken to paginate
          }
        });

        const historicOrdersData = historicOrdersResponse.data;

        if (!historicOrdersData || !historicOrdersData.listAllOrders) {
          break;
        }

        const items = historicOrdersData.listAllOrders.items.filter(item => item._deleted !== true);

        items.forEach(item => {
          allHistoricOrders.push(item);
        });

        nextToken = historicOrdersData.listAllOrders.nextToken;

      } while (nextToken);

      try {

        // Function to parse "DD/MM/YYYY" date format
        function parseDate(dateString) {
          if (!dateString) {
            return null;
          }
          const [day, month, year] = dateString.split('/');
          if (day && month && year) {
            return new Date(`${year}-${month}-${day}`);
          } else {
            return null;
          }
        }

        // Custom sorting logic
        function sortOrders(a, b) {
          const dateA = parseDate(a.orderReturnDate);
          const dateB = parseDate(b.orderReturnDate);

          if (dateA === null && dateB === null) {
            return 0; // Both dates are invalid
          } else if (dateA === null) {
            return 1; // Sort invalid dates to the end
          } else if (dateB === null) {
            return -1; // Sort invalid dates to the end
          } else {
            return dateA - dateB; // Normal date comparison
          }
        }

        // Filter and sort logic
        const filteredOrders = allHistoricOrders.filter(
          (order) => order.orderState === 'Gaminama' || order.orderState === 'Užsakyta'
        ).sort(sortOrders);

        const remainingOrders = allHistoricOrders.filter(
          (order) => order.orderState !== 'Gaminama' && order.orderState !== 'Užsakyta'
        ).sort(sortOrders);

        // Combine the sorted filtered and remaining orders
        const sortedHistoricOrders = [...filteredOrders, ...remainingOrders];

        let historicOrdersInDatabase = sortedHistoricOrders.length;

        // Set '' where value is null
        if (historicOrdersInDatabase !== historicOrderData.length) {
          sortedHistoricOrders.forEach((order, i) => {
            if (Number.isInteger(parseInt(order)) !== null) {
              let obj = {};

              Object.entries(order).forEach((el, index) => {
                if (el[1] !== null) {
                  let key = el[0];
                  let value = el[1];
                  obj[key] = value;
                } else {
                  let key = el[0];
                  let value = '';
                  obj[key] = value;
                }
              });

              addDataObjectToHistoricOrdersArray(obj);
            } else {
              return;
            }
          });
        }
      } catch (error) {
        setErrorDescription("Failed to sort orders");
        setErrorMessage(error);
        setErrorAlertDialogOpen(true);
      }

    } catch (error) {
      console.log(error)
      // setErrorDescription("Failed to get orders");
      // setErrorMessage(error);
      // setErrorAlertDialogOpen(true);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function isEmpty(str) {
    return (!str || str.length === 0);
  }

  function isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  function convertDateToSortable(dateString) {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // Months are zero-based in JavaScript
  }

  const saveNewDoctor = (event) => {
    const newDoctorName = event.target.value.replace(/[^a-z\sąčęėįšųūž]/gi, '');

    setNewDoctorValue(newDoctorName)
  };

  const addDoctorInput = () => {
    let emailToUse = isAdmin.includes(userEmail) ? currentUserChosen : userEmail;

    if (isEmpty(newDoctorValue) || isBlank(newDoctorValue)) {
      setErrorDescription("Cannot save Empty doctor!");
      setErrorMessage("Missing Vardas Pavardė");
      setErrorAlertDialogOpen(true);
      return
    } else {
      if (isEmpty(emailToUse) || isBlank(emailToUse)) {
        setErrorDescription("Vartotojas not selected!");
        setErrorMessage("Missing Vartotojas");
        setErrorAlertDialogOpen(true);
        return
      } else {
        const newDoctorNameNoSpaces = newDoctorValue.split(" ").join("_").toLowerCase();
        const newDoctor = { ...newDoctorState, id: (emailToUse + '_' + newDoctorNameNoSpaces), name: (newDoctorValue), clinicID: (emailToUse) }
        addDoctor(newDoctor)
        setNewDoctorValue('')
      }
    }
  }

  async function addDoctor(input) {
    try {
      await API.graphql(graphqlOperation(createDoctor, { input: input }))
      getDoctors()
      setOpen(false);
    } catch (err) {
      setErrorDescription("Failed to create doctor");
      setErrorMessage(err);
      setErrorAlertDialogOpen(true);
      setOpen(false);
    }
  }

  const addOrderToAllOrders = async (input) => {
    try {
      //console.log(input)
      await API.graphql(graphqlOperation(createAllOrders, { input: input }))
    } catch (err) {
      setErrorDescription("Failed to save order");
      setErrorMessage(err);
      setErrorAlertDialogOpen(true);
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }

  async function deleteOrderFromAllOrdersInput(selectedItems) {

    let uniqueHistoricOrderData = [...new Map(historicOrderData.map((item) => [item["id"], item])).values()];

    var toBeDeletedOrdersOnly = uniqueHistoricOrderData.filter(function (item) {
      return item.id &&
        item.orderID.startsWith(selectedItems.orderID)
    })

    const allOrderIDs = toBeDeletedOrdersOnly.map(order => order.id);

    for (var order in allOrderIDs) {
      deleteOrderFromAllOrders(allOrderIDs[order]);
    }

    setOpenOfDeleteOrder(false);

  }

  async function deleteOrderFromAllOrders(input) {

    setDeletingOrderAlertState(true)

    try {
      const deleteOrder = await API.graphql({
        query: mutations.deleteAllOrders,
        variables: { input: { "id": input, "_version": selectedOrderItems._version } }
      })

      // Check if the deletion was successful
      if (deleteOrder.data) {
        // Call refreshPage() only if the deletion was successful
        refreshPage();
      }

    } catch (err) {
      setErrorDescription("Failed to delete order");
      setErrorMessage(err);
      setErrorAlertDialogOpen(true);
    }
  }

  async function getOrderNumber() {
    try {
      const orderNumbersData = await API.graphql({
        query: queries.listOrderNumbers,
      })
      const currentOrder = orderNumbersData.data.listOrderNumbers.items[0].orderNumber
      const currentOrderVersion = orderNumbersData.data.listOrderNumbers.items[0]._version

      return [currentOrder, currentOrderVersion]
    } catch (error) {
      setErrorDescription("Failed to find order number");
      setErrorMessage(error);
      setErrorAlertDialogOpen(true);
    }
  }

  // // Function to change orderState by orderID
  // function changeOrderStateByOrderID(selectedItems, action) {
  //   // Update the state based on previous state
  //   setHistoricOrderData((prevOrders) => {
  //     // Find the order with the matching orderID
  //     const updatedOrders = prevOrders.map((order) => {
  //       if (order.id === selectedItems.id) {
  //         return { ...order, orderState: action };
  //       }
  //       return order;
  //     });

  //     return updatedOrders;
  //   });
  // }

  async function changeOrderState(selectedItems, action) {

    const input = {
      id: selectedItems.id,
      orderState: action,
      _version: selectedItems._version
    };

    await API.graphql(graphqlOperation(updateAllOrders, { input: input }))
      .then(function (res) {
        setHistoricOrderData((prevOrders) => {
          // Find the order with the matching orderID
          const updatedOrders = prevOrders.map((order) => {
            if (order.id === selectedItems.id) {
              return { ...order, orderState: action };
            }
            return order;
          });

          return updatedOrders;
        });
      })
      .catch(function (err) {
        setErrorDescription("Failed updating order state");
        setErrorMessage(err);
        setErrorAlertDialogOpen(true);
      })
  }

  // async function addClinicNameAttribute() {
  //   const inputString = `"511","2"
  //   "437","3"
  //   "336","3"
  //   "389","3"
  //   "335","3"`;

  //   const lines = inputString.split('\n');

  //   for (const line of lines) {
  //     const [id, _version] = line.replace(/"/g, '').split(',');

  //     console.log("ID: ", id.trim())
  //     console.log("Version: ", _version.trim())

  //     const input = {
  //       id: id.trim(),
  //       clinicName: "Prodentum",
  //       _version: _version.trim()
  //     };

  //     try {
  //       const res = await API.graphql(graphqlOperation(updateAllOrders, { input: input }));
  //       console.log("Updated state and return date", res);
  //       //refreshPage()
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // }

  async function changeOrderReturnDateAndState(selectedItems, orderReturnDate, action) {

    const input = {
      id: selectedItems.id,
      orderReturnDate: orderReturnDate,
      orderState: action,
      _version: selectedItems._version
    };

    await API.graphql(graphqlOperation(updateAllOrders, { input: input }))
      .then(function (res) {
        console.log("Updated state and return date")
        //refreshPage()
      })
      .catch(function (err) {
        setErrorDescription("Failed updating order state");
        setErrorMessage(err);
        setErrorAlertDialogOpen(true);
      })
  }

  async function incrementOrderNumber() {
    let isSuccess = false;
    let errorTries = 0;
    while (!isSuccess && errorTries < 2) { // loop till to isSuccess become true OR errorTries is less than 4
      try {

        let orderNumberData = await getOrderNumber()
        let orderNumber = orderNumberData[0],
          orderNumberVersion = orderNumberData[1]

        orderNumber = orderNumber + 1

        const input = {
          id: '0',
          orderNumber: orderNumber,
          _version: orderNumberVersion
        };

        console.log("Incrementing orderNumber: ", input)

        await API.graphql(graphqlOperation(updateOrderNumber, { input: input }))
          .then(function (res) {
            isSuccess = true;
          })
          .catch(function (err) {
            console.log("Failed to increment order number, retrying... ", err)
            errorTries = errorTries + 1
          })

        if (isSuccess === true) {
          return orderNumber
        }

      } catch (err) {
        setErrorDescription("Failed to increment order number");
        setErrorMessage(err);
        setErrorAlertDialogOpen(true);
        errorTries = errorTries + 1
      }
    }

    if (errorTries === 2) {
      return "Error while incrementing orderNumber"
    }
  }

  function getCurrentDate(separator = '/') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date < 10 ? `0${date}` : `${date}`}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`
  }

  const checkIfWeekend = (date) => {
    return dayjs(date).day() === 0 || dayjs(date).day() === 6;
  }

  const [expeditedState, setExpeditedState] = React.useState({
    expedited: false,
    superExpedited: false,
  });

  const [expeditedStateOfOrderCorrection, setExpeditedStateOfOrderCorrection] = React.useState({
    expedited: false,
    superExpedited: false,
  });

  function selectedDateTime(selectedDateTime) {

    const pickedDataForDayJs = dayjs(selectedDateTime)
    const currentDay = dayjs()

    const difference = pickedDataForDayJs.diff(currentDay, 'day') - 1

    let workingDaysDifference = 0

    for (let i = 0; i < difference; i++) {
      if (dayjs(dayjs(currentDay).add(i, 'day')).day() == 6 || 0) {
      } else {
        workingDaysDifference++
      }
    }

    if (workingDaysDifference < 6) {
      setExpeditedState({ expedited: true })
    } else {
      setExpeditedState({ expedited: false, superExpedited: false })
    }
  }

  function selectedDateTimeOfOrderCorrection(selectedDateTime) {

    const pickedDataForDayJs = dayjs(selectedDateTime)
    const currentDay = dayjs()

    const difference = pickedDataForDayJs.diff(currentDay, 'day') - 1

    let workingDaysDifference = 0

    for (let i = 0; i < difference; i++) {
      if (dayjs(dayjs(currentDay).add(i, 'day')).day() == 6 || 0) {
      } else {
        workingDaysDifference++
      }
    }

    if (workingDaysDifference < 6) {
      setExpeditedStateOfOrderCorrection({ expedited: true })
    } else {
      setExpeditedStateOfOrderCorrection({ expedited: false, superExpedited: false })
    }
  }

  const [nextNearestWorkingDay] = React.useState(getNextNearestWorkingDay());

  function getNextNearestWorkingDay() {
    if (dayjs().day() == 5) {
      return (dayjs().add(3, 'day'))
    }
    if (dayjs().day() == 6) {
      return (dayjs().add(3, 'day'))
    }
    if (dayjs().day() == 0) {
      return (dayjs().add(2, 'day'))
    }
    if (
      dayjs().day() == 1 ||
      dayjs().day() == 2 ||
      dayjs().day() == 3 ||
      dayjs().day() == 4
    ) {
      return (dayjs().add(1, 'day'))
    }
  }

  const initialSelectedFilesState = ('');

  const initialImplantState = ('');

  const initialColorState = ('');

  const initialAdditionalInfoState = ('');
  const initialAdditionalInfoOfOrderCorrectionState = ('');

  const initialTeethState = {
    t18: false,
    t17: false,
    t16: false,
    t15: false,
    t14: false,
    t13: false,
    t12: false,
    t11: false,
    t21: false,
    t22: false,
    t23: false,
    t24: false,
    t25: false,
    t26: false,
    t27: false,
    t28: false,
    t48: false,
    t47: false,
    t46: false,
    t45: false,
    t44: false,
    t43: false,
    t42: false,
    t41: false,
    t31: false,
    t32: false,
    t33: false,
    t34: false,
    t35: false,
    t36: false,
    t37: false,
    t38: false,
    thisTeeth: []
  };

  const initialTeethBridgeArrayState = {
    thisTeeth: []
  };

  const initialTeethInTheBridgeState = {
    t18: false,
    t17: false,
    t16: false,
    t15: false,
    t14: false,
    t13: false,
    t12: false,
    t11: false,
    t21: false,
    t22: false,
    t23: false,
    t24: false,
    t25: false,
    t26: false,
    t27: false,
    t28: false,
    t48: false,
    t47: false,
    t46: false,
    t45: false,
    t44: false,
    t43: false,
    t42: false,
    t41: false,
    t31: false,
    t32: false,
    t33: false,
    t34: false,
    t35: false,
    t36: false,
    t37: false,
    t38: false
  };

  const initialTeethBridgeState = {
    t18x: false,
    t17x: false,
    t16x: false,
    t15x: false,
    t14x: false,
    t13x: false,
    t12x: false,
    t11x: false,
    t21x: false,
    t22x: false,
    t23x: false,
    t24x: false,
    t25x: false,
    t26x: false,
    t27x: false,
    t28x: false,
    t48x: false,
    t47x: false,
    t46x: false,
    t45x: false,
    t44x: false,
    t43x: false,
    t42x: false,
    t41x: false,
    t31x: false,
    t32x: false,
    t33x: false,
    t34x: false,
    t35x: false,
    t36x: false,
    t37x: false,
    t38x: false
  };

  const initialInOrderState = {
    18: false,
    17: false,
    16: false,
    15: false,
    14: false,
    13: false,
    12: false,
    11: false,
    21: false,
    22: false,
    23: false,
    24: false,
    25: false,
    26: false,
    27: false,
    28: false,
    48: false,
    47: false,
    46: false,
    45: false,
    44: false,
    43: false,
    42: false,
    41: false,
    31: false,
    32: false,
    33: false,
    34: false,
    35: false,
    36: false,
    37: false,
    38: false,
  };

  const initialAlertState = {
    teethAlert: false,
    restorationAlert: false,
    implantAlert: false,
    colorAlert: false,
    teethExistsAlert: false,
    doctorAlert: false,
    patientNameAlert: false,
    patientDOBAlert: false,
    orderEmptyAlert: false,
    colorOrFilesAlert: false,
    additionalInfoValueOfOrderCorrectionAlert: false,
  };

  const initialLoadingAlertState = {
    loadingAlert: false,
  }

  const initialImplantFieldsEnabled = false

  const initialFileListArrayStateSTL = [];

  const initialFileListArrayState = [];

  const initialListOfPhotos = [];

  const theme = useTheme();
  const [restorationName, setRestorationName] = React.useState(null);
  const [implantName, setImplantName] = React.useState(initialImplantState);
  const [colorName, setColorName] = React.useState(initialColorState);
  const [additionalInfoValue, setAdditionalInfoValue] = React.useState(initialAdditionalInfoState);
  const [additionalInfoValueOfOrderCorrection, setAdditionalInfoValueOfOrderCorrection] = React.useState(initialAdditionalInfoOfOrderCorrectionState);
  const [checkedTeeth, setCheckedTeeth] = React.useState(initialTeethState);
  const [checkedTeethInTheBridge, setCheckedTeethInTheBridge] = React.useState(initialTeethInTheBridgeState);
  const [checkedTeethBridge, setCheckedTeethBridge] = React.useState(initialTeethBridgeState);
  const [checkedTeethBridgeArray, setCheckedTeethBridgeArray] = React.useState(initialTeethBridgeArrayState);
  const [inOrder, setInOrder] = React.useState(initialInOrderState);
  const [alertState, setAlertState] = React.useState(initialAlertState)
  const [loadingAlertState, setLoadingAlertState] = React.useState(initialLoadingAlertState)
  const [creatingOrderAlertState, setCreatingOrderAlertState] = React.useState(false)
  const [deletingOrderAlertState, setDeletingOrderAlertState] = React.useState(false)
  const [fileList, setFileList] = React.useState(initialSelectedFilesState)
  const [fileListArray, setFileListArray] = React.useState(initialFileListArrayState)
  const [fileListArraySTL, setFileListArraySTL] = React.useState(initialFileListArrayStateSTL)
  const [listOfPhotos, setListOfPhotos] = React.useState(initialListOfPhotos)
  const [implantFieldsEnabled, setImplantFieldsEnabled] = React.useState(initialImplantFieldsEnabled)
  const [orderLoaded, setOrderLoaded] = React.useState(false)
  const [newOrderShow, setNewOrderShow] = React.useState(false)

  const resetOrderData = () => {
    setOrderData(initialOrderDataState)
  }

  const resetOrderDataOfCorrection = () => {
    setOrderDataOfCorrection(initialOrderDataStateOfCorrection)
  }

  const resetFilesToUploadListSTL = () => {
    setFilesToUploadListSTL([])
  }

  const resetFilesToUploadList = () => {
    setFilesToUploadList([])
  }

  const resetFilesToUploadListSTLOfCorrection = () => {
    setFilesToUploadListSTLOfCorrection([])
  }

  const resetFilesToUploadListOfCorrection = () => {
    setFilesToUploadListOfCorrection([])
  }

  const resetDoctor = () => {
    setDoctor('')
  }

  const resetPatientName = () => {
    setPatientName('')
  }

  const resetPatientDOB = () => {
    setPatientDOB('')
  }

  const resetValueAhead = () => {
    setValueAhead(initialValueAheadState)
  }

  const resetOrderCorrectionReturnDate = () => {
    setOrderCorrectionReturnDate(initialOrderCorrectionReturnDateState)
  }

  const resetPatientGender = () => {
    setPatientGender('female')
  }

  const resetPatientAdditionalInfo = () => {
    setPatientAdditionalInfo('')
  }

  const resetRestorationState = () => {
    setRestorationName(null);
  };

  const resetImplantState = () => {
    setImplantName(initialImplantState);
  };

  const resetFileListState = () => {
    setFileList(initialSelectedFilesState);
  };

  const resetFileListArrayState = () => {
    setFileListArray(initialFileListArrayState);
  };

  const resetFileListArrayStateSTL = () => {
    setFileListArraySTL(initialFileListArrayStateSTL);
  };

  const resetColorState = () => {
    setColorName(initialColorState);
  };

  const resetAdditionalInfoState = () => {
    setAdditionalInfoValue(initialAdditionalInfoState);
  };

  const resetAdditionalInfoOfCorrectionState = () => {
    setAdditionalInfoValueOfOrderCorrection(initialAdditionalInfoOfOrderCorrectionState);
  };

  const resetTeethState = () => {
    setCheckedTeeth(initialTeethState);
  };

  const resetAlertState = () => {
    setAlertState(initialAlertState);
  };

  const resetCheckedTeethBridge = () => {
    setCheckedTeethBridge(initialTeethBridgeState);
  };

  const resetCheckedTeethInTheBridge = () => {
    setCheckedTeethInTheBridge(initialTeethBridgeState);
  };

  const resetCheckedTeethBridgeArray = () => {
    setCheckedTeethBridgeArray(initialTeethBridgeArrayState);
  };

  const resetNumberOfImplantComponents = () => {
    setNumberOfImplantComponents([]);
  }

  function restorationOnChange(value) {

    setRestorationName(
      [value]
    );

    for (var i = 0; i < restorationEnablingImplants.length; i++) {
      if (restorationEnablingImplants[i] == value) {
        setImplantFieldsEnabled(true)
        break;
      } else { setImplantFieldsEnabled(false) }
    }

    setAlertState(prevAlertState => ({
      ...alertState,
      restorationAlert: false
    }))
  };

  function implantOnChange(number, value) {

    setImplantName((prev) => {
      return { ...prev, [number]: value }
    })
  };

  const colorHandleChange = (event) => {
    setColorName(event.target.value);
    resetAlertState()
  };

  const additionalInfoHandleChange = (event) => {
    setAdditionalInfoValue(event.target.value);
  };

  const additionalInfoHandleChangeOfOrderCorrection = (event) => {
    setAdditionalInfoValueOfOrderCorrection(event.target.value);
  };

  function errorAlert() {
    if (alertState.colorOrFilesAlert === true) {
      return (<Alert variant="filled" severity="error">
        Pasirinkite spalvą arba įkelkite nuotraukas!
      </Alert>);
    }
    if (alertState.orderEmptyAlert === true) {
      return (<Alert variant="filled" severity="error">
        Nesuformuotas užsakymas!
      </Alert>);
    }
    if (alertState.patientDOBAlert === true) {
      return (<Alert variant="filled" severity="error">
        Nenurodyti paciento gimimo metai!
      </Alert>);
    }
    if (alertState.patientNameAlert === true) {
      return (<Alert variant="filled" severity="error">
        Nenurodytas paciento vardas ir pavardė!
      </Alert>);
    }
    if (alertState.doctorAlert === true) {
      return (<Alert variant="filled" severity="error">
        Nepasirinktas gydytojas!
      </Alert>);
    }
    if (alertState.teethAlert === true) {
      return (<Alert variant="filled" severity="error">
        Nepažymėtas nei vienas dantis!
      </Alert>);
    }
    if (alertState.restorationAlert === true) {
      return (<Alert variant="filled" severity="error">
        Nepasirinkta restauracija!
      </Alert>);
    }
    if (alertState.implantAlert === true) {
      return (<Alert variant="filled" severity="error">
        Nepasirinkti implantai!
      </Alert>);
    }
    if (alertState.colorAlert === true) {
      return (<Alert variant="filled" severity="error">
        Nepasirinkta spalva!
      </Alert>);
    }
    if (alertState.teethExistsAlert === true) {
      return (<Alert variant="filled" severity="error">
        Toks dantis jau pridėtas į užsakymą!
      </Alert>);
    }
    if (alertState.additionalInfoValueOfOrderCorrectionAlert === true) {
      return (<Alert variant="filled" severity="error">
        Nenurodėte jokios korekcijos informacijos!
      </Alert>);
    }
  }

  function loadingAlert() {
    if (loadingAlertState.loadingAlert === true) {
      return (<Alert variant="filled" severity="error">
        KRAUNASI!
      </Alert>);
    }
  }

  function creatingOrderAlert() {
    if (creatingOrderAlertState === true) {
      return (<Alert variant="filled" severity="error">
        KURIAMAS UŽSAKYMAS ... !
      </Alert>);
    }
  }

  function deletingOrderAlert() {
    if (deletingOrderAlertState === true) {
      return (<Alert variant="filled" severity="error">
        TRINAMAS UŽSAKYMAS ... !
      </Alert>);
    }
  }

  function getSelectedTeethArrayState(value) {

    let state = checkedTeeth.thisTeeth

    if (state.includes(value)) {
      let index = state.indexOf(value)
      state.splice(index, 1);
    }
    else state.push(value)
    return state
  }

  function getSelectedBridgedTeethArrayState(value) {

    let state = checkedTeethBridgeArray.thisTeeth

    if (state.includes(value)) {
      let index = state.indexOf(value)
      state.splice(index, 1);
    }
    else state.push(value)
    return state
  }

  const deleteValueFromNumberOfImplantComponents = (indexOfValue) => {
    setNumberOfImplantComponents([
      ...numberOfImplantComponents.slice(0, indexOfValue),
      ...numberOfImplantComponents.slice(indexOfValue + 1, numberOfImplantComponents.length)
    ])
  }

  const tHandleChange = (event) => {
    let { name, value, checked } = event.target

    if (checked == false) {
      checkedTeeth.thisTeeth.map((element) => {
        if (element == value) {
          getSelectedTeethArrayState(element)
          setCheckedTeeth(checkedTeeth => {
            return {
              ...checkedTeeth,
              [name]: checked,
            }
          })
          let indexOfValue = numberOfImplantComponents.indexOf(value)
          deleteValueFromNumberOfImplantComponents(indexOfValue)
        }
      })

    } else {

      setAlertState(prevAlertState => ({
        ...alertState,
        teethAlert: false
      }))

      let newTeeth = getSelectedTeethArrayState(value)

      setNumberOfImplantComponents(oldArray => [...oldArray, value])
      setCheckedTeeth(checkedTeeth => {
        return {
          ...checkedTeeth,
          [name]: checked,
          thisTeeth: newTeeth
        }
      })
    }
  }

  const teethBridgeHandleChange = (event) => {
    let { name, value, checked } = event.target

    setCheckedTeethBridge(checkedTeethBridge => {
      return {
        ...checkedTeethBridge,
        [name]: checked
      }
    })

    let bridgeNumber = value.slice(0, -1);

    const toothMappings = {
      't18x': { left: 't18', right: 't17' },
      't17x': { left: 't17', right: 't16' },
      't16x': { left: 't16', right: 't15' },
      't15x': { left: 't15', right: 't14' },
      't14x': { left: 't14', right: 't13' },
      't13x': { left: 't13', right: 't12' },
      't12x': { left: 't12', right: 't11' },
      't11x': { left: 't11', right: 't21' },
      't21x': { left: 't21', right: 't22' },
      't22x': { left: 't22', right: 't23' },
      't23x': { left: 't23', right: 't24' },
      't24x': { left: 't24', right: 't25' },
      't25x': { left: 't25', right: 't26' },
      't26x': { left: 't26', right: 't27' },
      't27x': { left: 't27', right: 't28' },
      't48x': { left: 't48', right: 't47' },
      't47x': { left: 't47', right: 't46' },
      't46x': { left: 't46', right: 't45' },
      't45x': { left: 't45', right: 't44' },
      't44x': { left: 't44', right: 't43' },
      't43x': { left: 't43', right: 't42' },
      't42x': { left: 't42', right: 't41' },
      't41x': { left: 't41', right: 't31' },
      't31x': { left: 't31', right: 't32' },
      't32x': { left: 't32', right: 't33' },
      't33x': { left: 't33', right: 't34' },
      't34x': { left: 't34', right: 't35' },
      't35x': { left: 't35', right: 't36' },
      't36x': { left: 't36', right: 't37' },
      't37x': { left: 't37', right: 't38' },
    };

    let teethToLeft = ''
    let teethToRight = ''

    if (name == 't18x') {
      teethToLeft = 't18'
      teethToRight = 't17'
    }

    if (name == 't17x') {
      teethToLeft = 't17'
      teethToRight = 't16'
    }

    if (name == 't16x') {
      teethToLeft = 't16'
      teethToRight = 't15'
    }

    if (name == 't15x') {
      teethToLeft = 't15'
      teethToRight = 't14'
    }

    if (name == 't14x') {
      teethToLeft = 't14'
      teethToRight = 't13'
    }

    if (name == 't13x') {
      teethToLeft = 't13'
      teethToRight = 't12'
    }

    if (name == 't12x') {
      teethToLeft = 't12'
      teethToRight = 't11'
    }

    if (name == 't11x') {
      teethToLeft = 't11'
      teethToRight = 't21'
    }

    if (name == 't21x') {
      teethToLeft = 't21'
      teethToRight = 't22'
    }

    if (name == 't22x') {
      teethToLeft = 't22'
      teethToRight = 't23'
    }

    if (name == 't23x') {
      teethToLeft = 't23'
      teethToRight = 't24'
    }

    if (name == 't24x') {
      teethToLeft = 't24'
      teethToRight = 't25'
    }

    if (name == 't25x') {
      teethToLeft = 't25'
      teethToRight = 't26'
    }

    if (name == 't26x') {
      teethToLeft = 't26'
      teethToRight = 't27'
    }

    if (name == 't27x') {
      teethToLeft = 't27'
      teethToRight = 't28'
    }

    // --------------------------------------

    if (name == 't48x') {
      teethToLeft = 't48'
      teethToRight = 't47'
    }

    if (name == 't47x') {
      teethToLeft = 't47'
      teethToRight = 't46'
    }

    if (name == 't46x') {
      teethToLeft = 't46'
      teethToRight = 't45'
    }

    if (name == 't45x') {
      teethToLeft = 't45'
      teethToRight = 't44'
    }

    if (name == 't44x') {
      teethToLeft = 't44'
      teethToRight = 't43'
    }

    if (name == 't43x') {
      teethToLeft = 't43'
      teethToRight = 't42'
    }

    if (name == 't42x') {
      teethToLeft = 't42'
      teethToRight = 't41'
    }

    if (name == 't41x') {
      teethToLeft = 't41'
      teethToRight = 't31'
    }

    if (name == 't31x') {
      teethToLeft = 't31'
      teethToRight = 't32'
    }

    if (name == 't32x') {
      teethToLeft = 't32'
      teethToRight = 't33'
    }

    if (name == 't33x') {
      teethToLeft = 't33'
      teethToRight = 't34'
    }

    if (name == 't34x') {
      teethToLeft = 't34'
      teethToRight = 't35'
    }

    if (name == 't35x') {
      teethToLeft = 't35'
      teethToRight = 't36'
    }

    if (name == 't36x') {
      teethToLeft = 't36'
      teethToRight = 't37'
    }

    if (name == 't37x') {
      teethToLeft = 't37'
      teethToRight = 't38'
    }

    setCheckedTeeth(checkedTeeth => {
      return {
        ...checkedTeeth,
        [teethToLeft]: checked,
      }
    })
    setCheckedTeethInTheBridge(checkedTeethInTheBridge => {
      return {
        ...checkedTeethInTheBridge,
        [teethToLeft]: checked,
      }
    })

    setCheckedTeeth(checkedTeeth => {
      return {
        ...checkedTeeth,
        [teethToRight]: checked,
      }
    })
    setCheckedTeethInTheBridge(checkedTeethInTheBridge => {
      return {
        ...checkedTeethInTheBridge,
        [teethToRight]: checked,
      }
    })

    let bridged = teethToLeft.substring(1) + '-' + teethToRight.substring(1)
    let bridgedWithBridge = teethToLeft + '-' + value + '-' + teethToRight

    let newTeeth = ''

    if (checked == false) {

      checkedTeeth.thisTeeth.map((element) => {
        if (element.includes('-') == true && element.includes(bridgeNumber) == true) {

          checkedTeethBridgeArray.thisTeeth.map((belement) => {
            if (belement.includes(value) == true) {
              getSelectedBridgedTeethArrayState(belement)
              let bridgesInBridge = belement.split('-')
              bridgesInBridge.forEach((bridge) => {
                if (bridge.includes('x') == true) {
                  bridge = "t" + bridge
                  setCheckedTeethBridge(checkedTeethBridge => {
                    return {
                      ...checkedTeethBridge,
                      [bridge]: checked
                    }
                  })
                }
              })
            }
          })

          getSelectedTeethArrayState(element)
          let teethInBridge = element.split('-')
          teethInBridge.forEach((tooth) => {
            tooth = "t" + tooth
            setCheckedTeeth(checkedTeeth => {
              return {
                ...checkedTeeth,
                [tooth]: checked,
              }
            })
            setCheckedTeethInTheBridge(checkedTeethInTheBridge => {
              return {
                ...checkedTeethInTheBridge,
                [tooth]: checked,
              }
            })
          })
        }
      })

    } else {

      setAlertState(prevAlertState => ({
        ...alertState,
        teethAlert: false
      }))

      let nearbyBridgeJoined = false

      let joined = []
      let twicejoined = []

      let firstBridgeForRemoval
      let secondBridgeForRemoval

      if (checkedTeeth.thisTeeth.length !== 0) {
        checkedTeeth.thisTeeth.map((element) => {
          if (element.includes(teethToLeft.substring(1)) == true || element.includes(teethToRight.substring(1)) == true) {
            if (joined.length == 0) {
              joined = Array.from(new Set([element, bridged].join('-').split('-'))).join('-');
              firstBridgeForRemoval = element
            } else {
              twicejoined = Array.from(new Set([element, joined].join('-').split('-'))).join('-');
              secondBridgeForRemoval = element
              joined = []
            }
          }
        })
        if (joined.length == 0 && twicejoined == 0) {
          nearbyBridgeJoined = false
        } else if (joined.length !== 0) {
          getSelectedTeethArrayState(firstBridgeForRemoval)
          newTeeth = getSelectedTeethArrayState(joined)
          nearbyBridgeJoined = true
        } else {
          getSelectedTeethArrayState(firstBridgeForRemoval)
          getSelectedTeethArrayState(secondBridgeForRemoval)
          newTeeth = getSelectedTeethArrayState(twicejoined)
          nearbyBridgeJoined = true
        }
      }

      let joinedForBridgeArray = []
      let twicejoinedForBridgeArray = []

      let firstBridgeForRemovalForBridgeArray
      let secondBridgeForRemovalForBridgeArray

      if (checkedTeethBridgeArray.thisTeeth.length !== 0) {
        checkedTeethBridgeArray.thisTeeth.map((element) => {
          if (element.includes(teethToLeft) == true || element.includes(teethToRight) == true) {
            if (joinedForBridgeArray.length == 0) {
              joinedForBridgeArray = Array.from(new Set([element, bridgedWithBridge].join('-').split('-'))).join('-');
              firstBridgeForRemovalForBridgeArray = element
            } else {
              twicejoinedForBridgeArray = Array.from(new Set([element, joinedForBridgeArray].join('-').split('-'))).join('-');
              secondBridgeForRemovalForBridgeArray = element
              joinedForBridgeArray = []
            }
          }
        })
        if (joinedForBridgeArray.length == 0 && twicejoinedForBridgeArray == 0) {
          nearbyBridgeJoined = false
        } else if (joinedForBridgeArray.length !== 0) {
          getSelectedBridgedTeethArrayState(firstBridgeForRemovalForBridgeArray)
          newTeeth = getSelectedBridgedTeethArrayState(joinedForBridgeArray)
          nearbyBridgeJoined = true
        } else {
          getSelectedBridgedTeethArrayState(firstBridgeForRemovalForBridgeArray)
          getSelectedBridgedTeethArrayState(secondBridgeForRemovalForBridgeArray)
          newTeeth = getSelectedBridgedTeethArrayState(twicejoinedForBridgeArray)
          nearbyBridgeJoined = true
        }
      }

      if (nearbyBridgeJoined !== true) {
        newTeeth = getSelectedTeethArrayState(bridged)
        let newBridgedTeeth = getSelectedBridgedTeethArrayState(bridgedWithBridge)
      }

      if (restorationName == '') {
        setImplantFieldsEnabled(false)
      } else {
        for (var i = 0; i < restorationEnablingImplants.length; i++) {
          if (restorationEnablingImplants[i] == restorationName) {
            setImplantFieldsEnabled(true)
            break;
          } else { setImplantFieldsEnabled(false) }
        }
      }
    }
  }

  const initialOrderDataState = [];

  const initialOrderDataStateOfCorrection = [];

  const initialHistoricOrderDataState = [];

  const [orderData, setOrderData] = useState(initialOrderDataState);
  const [orderDataOfCorrection, setOrderDataOfCorrection] = useState(initialOrderDataStateOfCorrection);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState('');
  const [clinic, setClinic] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [historicOrderData, setHistoricOrderData] = useState(initialHistoricOrderDataState);

  // ✅ Add an object to a state array
  const addFileObjectToArraySTL = obj => {
    setFileListArraySTL(current => [...current, obj]);
  };

  // ✅ Add an object to a state array
  const addFileObjectToArray = obj => {
    setFileListArray(current => [...current, obj]);
  };

  // ✅ Add an object to a state array
  const addDataObjectToArray = obj => {
    setOrderData(current => [...current, obj]);
  };

  // ✅ Add an object to a state array
  const addDataObjectToArrayOfCorrection = obj => {
    setOrderDataOfCorrection(current => [...current, obj]);
  };

  // ✅ Add an object to a state array
  const addDataObjectToHistoricOrdersArray = obj => {
    setHistoricOrderData(current => [...current, obj]);
  };

  const deleteFromPreOrder = (event) => {

    const { value } = event.target

    let indexToDelete = ''

    orderData.map((valueInOrder, i) => {
      if (value == i) {

        indexToDelete = value

        setInOrder(inOrder => {
          return {
            ...inOrder,
            [valueInOrder.selectedTeethName]: false,
          }
        })
      }
    })

    setOrderData((current) =>
      current.filter((order, index) => index !== parseInt(indexToDelete))
    );

  }

  const newOrder = () => {

    resetAlertState()

    listUsers(60);

    resetFilesToUploadList()
    resetFilesToUploadListSTL()
    resetFilesToUploadListOfCorrection()
    resetFilesToUploadListSTLOfCorrection()
    resetFileListArrayState()
    resetOrderData()
    resetOrderDataOfCorrection()
    setOrderLoaded(false)
    setNewOrderShow(true)
    resetDoctor()
    resetPatientName()
    resetPatientDOB()
    resetValueAhead()
    resetOrderCorrectionReturnDate()
    setExpeditedStateOfOrderCorrection({ expedited: false })
    resetAdditionalInfoOfCorrectionState()
    resetPatientGender()
    resetPatientAdditionalInfo()
    resetRestorationState()
    resetColorState()

    //getHistoricOrders()
  }

  const addOrder = async () => {

    resetAlertState()

    let preOrderTeethName = checkedTeeth.thisTeeth.map(e => "t" + e).join("/")
    let preOrderTeeth = checkedTeeth.thisTeeth.join('/');

    if (preOrderTeethName.includes('-') == true) {
      preOrderTeethName = preOrderTeethName.split("-").join("-t");
    }

    if (doctorChosen == '') {
      setAlertState(prevAlertState => ({
        ...alertState,
        doctorAlert: true
      }))
      return false
    }

    if (patientName == '') {
      setAlertState(prevAlertState => ({
        ...alertState,
        patientNameAlert: true
      }))
      return false
    }

    // if (patientDOB.length === 0) {
    //   setAlertState(prevAlertState => ({
    //     ...alertState,
    //     patientDOBAlert: true
    //   }))
    //   return false
    // }

    if (checkedTeeth.thisTeeth.length === 0) {
      setAlertState(prevAlertState => ({
        ...alertState,
        teethAlert: true
      }))
      return false
    }

    if (restorationName == null) {
      setAlertState(prevAlertState => ({
        ...alertState,
        restorationAlert: true
      }))

      return false
    }

    if (filesToUploadList.length !== 0 || colorName !== '') {
    } else {
      setAlertState(prevAlertState => ({
        ...alertState,
        colorOrFilesAlert: true
      }))
      return false
    }

    let implantNameArray = Object.keys(implantName).map((key) => [Number(key), implantName[key]]);

    addDataObjectToArray({
      selectedTeeth: checkedTeeth.thisTeeth,
      selectedTeethName: preOrderTeethName,
      selectedRestoration: restorationName,
      selectedImplant: implantNameArray,
      selectedColor: colorName,
      selectedAdditionalInfo: additionalInfoValue,
    })

    resetTeethState();
    resetColorState();
    resetImplantState();
    resetNumberOfImplantComponents();
    resetCheckedTeethBridge();
    resetCheckedTeethInTheBridge();
    resetCheckedTeethBridgeArray();
    resetRestorationState();
    resetAdditionalInfoState();
    resetFileListState();
    setImplantFieldsEnabled(false)

  }

  function getExtension(filename) {
    return filename.split('.').pop()
  }

  const uploadFiles = async (files) => {
    if (files.length !== 0) {

      const uploadPromises = files.map(async (file) => {
        try {
          await Storage.put(file.name, file, {
            contentType: file.type,
          });
        } catch (err) {
          console.log("uploadFiles - Failed to upload a file")
          setErrorDescription("Failed to upload a file");
          setErrorMessage(err);
          setErrorAlertDialogOpen(true);
        }
      });

      await Promise.all(uploadPromises); // Wait for all uploads to complete

    }
  };

  const createOrder = async () => {

    if (orderData.length !== 0) {

      setAlertState(prevAlertState => ({
        ...alertState,
        orderEmptyAlert: false
      }))

      setCreatingOrderAlertState(true)

      incrementOrderNumber()
        .then(async (res) => {

          let orderID = res
          let parsedOrderReturnDate = valueAhead && dayjs.isDayjs(valueAhead) ? valueAhead.format('DD/MM/YYYY') : '';

          console.log("filesToUploadList: ", filesToUploadList)
          let filesToUploadListIncludingOrderID = []
          let fileNameList = []

          // Create an array to store all the promises for asynchronous operations
          const promises = [];

          filesToUploadList.forEach((file) => {
            const compressionPromise = new Promise(async (resolve, reject) => {
              try {
                if (getExtension(file.name).toLowerCase() === 'heic') {
                  console.log('DETECTED HEIC !');

                  const jpegFileName =
                    file.name.substr(0, file.name.lastIndexOf('.')) + '.jpeg';
                  fileNameList.push(jpegFileName);
                  console.log('jpegFileName: ', jpegFileName);

                  const arrayBuffer = await file.arrayBuffer();
                  const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
                  console.log(blob);

                  // Convert HEIC to JPEG
                  const conversionResult = await heic2any({ blob, toType: 'image/jpeg' });

                  // Use Compressor.js to compress the converted JPEG
                  new Compressor(conversionResult, {
                    quality: 0.6,
                    mimeType: 'image/jpeg',
                    success(result) {
                      const jpegFile = new File([result], orderID.toString().concat('_' + jpegFileName), { type: 'image/jpeg' });
                      filesToUploadListIncludingOrderID.push(jpegFile);
                      resolve(); // Resolve the promise when compression is complete
                    },
                    error(err) {
                      console.error(err);
                      reject(err); // Reject the promise if there's an error
                    },
                  });
                } else {
                  fileNameList.push(file.name);

                  // Use Compressor.js to compress non-HEIC files directly
                  new Compressor(file, {
                    quality: 0.6,
                    success(result) {
                      const compressedFile = new File([result], orderID.toString().concat('_' + file.name), { type: file.type });
                      filesToUploadListIncludingOrderID.push(compressedFile);
                      resolve(); // Resolve the promise when compression is complete
                    },
                    error(err) {
                      console.error(err);
                      resolve(); // Resolve even if there's an error to continue processing other files
                    },
                  });
                }
              } catch (error) {
                setErrorDescription("Failed to convert/compres file");
                setErrorMessage(error);
                setErrorAlertDialogOpen(true);
                reject(error); // Reject the promise if there's an error during processing
              }
            });

            promises.push(compressionPromise); // Add the compression promise to the array
          });

          let filesToUploadListIncludingOrderIDSTL = []
          let fileNameListSTL = []
          filesToUploadListSTL.map(file => {
            fileNameListSTL.push(file.name)
            const updatedFileName = new File([file], orderID.toString().concat('_' + file.name), { type: file.type });
            filesToUploadListIncludingOrderIDSTL.push(updatedFileName)
          })

          let fileListString = ''
          if (fileNameList !== 0) {
            fileListString = fileNameList.join(":");
          }

          let fileListStringSTL = ''
          if (fileNameListSTL !== 0) {
            fileListStringSTL = fileNameListSTL.join(":");
          }

          // After all promises have resolved (i.e., all compression is complete), set the files to upload
          Promise.all(promises)
            .then(async () => {
              // All asynchronous operations are complete, set the files to upload
              await uploadFiles(filesToUploadListIncludingOrderID)
              await uploadFiles(filesToUploadListIncludingOrderIDSTL)

              for (const items of orderData) {
                const index = orderData.indexOf(items); // Get the index of the current 'items' object

                console.log(index, items)

                let implantString = items.selectedImplant.map(a => a.join(":")).join(";");
                let parsedImplants = '';

                if (items.selectedImplant.length > 1) {
                  items.selectedImplant.forEach((line) => {
                    line.forEach((teethnumber) => {
                      parsedImplants = parsedImplants.concat(teethnumber + `:`);
                    });
                  });
                }

                let clinicMakingOrder = user.attributes.email;
                let clinicMakingOrderName = user.attributes.name;

                if (isAdmin.includes(userEmail)) {
                  clinicMakingOrderName = currentUsers.find(clinic => clinic.email === currentUserChosen)?.name ?? '';
                  clinicMakingOrder = currentUserChosen
                }

                const finalisedOrderData = {
                  ...finalisedOrderDataState,
                  id: (index + orderID),
                  orderID: (orderID),
                  clinic: (clinicMakingOrder),
                  clinicName: (clinicMakingOrderName),
                  doctor: (doctorChosen),
                  patient: (patientName),
                  patientDOB: (patientDOB),
                  patientGender: (patientGender),
                  patientOtherInfo: (patientAdditionalInfo),
                  orderDate: (getCurrentDate()),
                  orderReturnDate: (parsedOrderReturnDate),
                  teeth: (items.selectedTeeth.toString()),
                  teethName: (items.selectedTeethName),
                  restoration: (items.selectedRestoration.toString()),
                  implants: (implantString),
                  color: (items.selectedColor),
                  additionalInfo: (items.selectedAdditionalInfo),
                  orderState: ("Užsakyta"),
                  expedited: (expeditedState.expedited),
                  fileList: (fileListString),
                  fileListSTL: (fileListStringSTL),
                };

                await addOrderToAllOrders(finalisedOrderData);

              }

            })
            .then(async () => {
              // All asynchronous operations are complete, so you can call refreshPage() here
              refreshPage();
            })
            .catch((error) => {
              // Handle errors if any of the promises reject
              setErrorDescription("Failed to upload files");
              setErrorMessage(error);
              setErrorAlertDialogOpen(true);
            });

        })
        .catch(function (err) {
          setErrorDescription("Failed to create order");
          setErrorMessage(err);
          setErrorAlertDialogOpen(true);
          console.log(err);
        })

    } else {
      setAlertState(prevAlertState => ({
        ...alertState,
        orderEmptyAlert: true
      }))
    }

  }

  const createCorrection = async () => {

    if (additionalInfoValueOfOrderCorrection == '') {
      setAlertState(prevAlertState => ({
        ...alertState,
        additionalInfoValueOfOrderCorrectionAlert: true
      }))
      return false
    }

    let forOrderState = {
      id: selectedOrderNumber,
      _version: orderVersion
    };

    incrementOrderNumber()
      .then(async (res) => {
        let orderID = res
        let parsedOrderReturnDate = orderCorrectionReturnDate.format('DD/MM/YYYY')

        let filesToUploadListIncludingOrderID = []
        let fileNameList = []
        filesToUploadListOfCorrection.map(file => {
          fileNameList.push(file.name)
          const updatedFileName = new File([file], selectedOrderNumber.toString().concat('k' + orderID + '_' + file.name), { type: file.type });
          filesToUploadListIncludingOrderID.push(updatedFileName)
        })

        await uploadFiles(filesToUploadListIncludingOrderID)

        let fileListString = ''
        if (fileNameList !== 0) {
          fileListString = fileNameList.join(":");
        }

        let filesToUploadListIncludingOrderIDSTL = []
        let fileNameListSTL = []
        filesToUploadListSTLOfCorrection.map(file => {
          fileNameListSTL.push(file.name)
          const updatedFileName = new File([file], selectedOrderNumber.toString().concat('k' + orderID + '_' + file.name), { type: file.type });
          filesToUploadListIncludingOrderIDSTL.push(updatedFileName)
        })

        await uploadFiles(filesToUploadListIncludingOrderIDSTL)

        let fileListStringSTL = ''
        if (fileNameListSTL !== 0) {
          fileListStringSTL = fileNameListSTL.join(":");
        }

        let clinicMakingOrder = user.attributes.email;

        if (isAdmin.includes(userEmail)) {
          clinicMakingOrder = clinic
        }

        const finalisedOrderData = {
          ...finalisedOrderDataState,
          id: (selectedOrderNumber + orderID),
          orderID: (selectedOrderNumber + 'k' + orderID),
          clinic: (clinicMakingOrder),
          orderCorrectionDate: (getCurrentDate()),
          orderCorrectionReturnDate: (parsedOrderReturnDate),
          orderCorrectionInformation: (additionalInfoValueOfOrderCorrection),
          fileListOfCorrection: (fileListString),
          fileListSTLOfCorrection: (fileListStringSTL),
        }

        await addOrderToAllOrders(finalisedOrderData);

        await changeOrderReturnDateAndState(forOrderState, parsedOrderReturnDate, "Užsakyta")

        refreshPage()

      })
      .catch(function (err) {
        setErrorDescription("Failed to create correction order");
        setErrorMessage(err);
        setErrorAlertDialogOpen(true);
      })
  }

  const [restorationEditorOpen, setRestorationEditorOpen] = React.useState(false);

  const handleClickOpenOfRestorationEditor = () => {
    setRestorationEditorOpen(true);
  };

  const handleCloseOfRestorationEditor = () => {
    setRestorationEditorOpen(false);
  };

  function Counter() {
    return (
      <TableCell>
        <PhotoProvider>
          <div className="foo">
            {
              listOfPhotos.map((item, index) => (
                <PhotoView key={index} src={"https://files114336-dev.s3.eu-central-1.amazonaws.com/public/" + selectedOrderNumber + "_" + item}>
                  <span key={index + item + "Correction_files"}><button className="link-button" onClick={() => setVisible(true)}>{item}</button><br /></span>
                </PhotoView>
              ))
            }
          </div>
        </PhotoProvider>
      </TableCell>
    );
  };

  function ErrorAlertDialog() {
    return (
      <Dialog open={errorAlertDialogOpen}>
        <DialogTitle>ERROR</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>{errorDescription}</b> - {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setErrorAlertDialogOpen(false)
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function RestorationEditor() {
    return (
      <Dialog
        fullScreen
        open={restorationEditorOpen}
        onClose={handleCloseOfRestorationEditor}
        TransitionComponent={Transition}
      >
        <ThemeProvider theme={themeOfMenu}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseOfRestorationEditor}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                REDAGAVIMAS
              </Typography>
            </Toolbar>
          </AppBar>
        </ThemeProvider>

        <Grid container spacing={0} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={5}>
            <h1>Restauracijos</h1>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                onBlur={restorationInput}
                id="Restauracijos"
                label="Restauracijos"
                placeholder="Placeholder"
                multiline
                maxRows={50}
                type="text"
                defaultValue={restoration.join('\n')}
              />
              <Button variant="contained" color="success" onClick={addRestorations} sx={{ ml: 1 }}>
                IŠSAUGOTI
              </Button>
            </Box>
          </Grid>

          <Grid item xs={5}>
            <h1>Restauracijos implantams</h1>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                onBlur={restorationEnablingImplantsInput}
                id="Restauracijos implantams"
                label="Restauracijos implantams"
                placeholder="Placeholder"
                multiline
                maxRows={50}
                type="text"
                defaultValue={restorationEnablingImplants.join('\n')}
              />
              <Button variant="contained" color="success" onClick={addRestorationsEnablingImplants} sx={{ ml: 1 }}>
                IŠSAUGOTI
              </Button>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <h1>Implantai</h1>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                onBlur={implantsInput}
                id="Implantai"
                label="Implantai"
                placeholder="Placeholder"
                multiline
                maxRows={50}
                type="text"
                defaultValue={implant.join('\n')}
              />
              <Button variant="contained" color="success" onClick={addImplants} sx={{ ml: 1 }}>
                IŠSAUGOTI
              </Button>
            </Box>
          </Grid>
        </Grid>

      </Dialog>
    );
  }

  // function Menu() {
  //   return (
  //     <AppBar sx={{ position: 'static' }}>
  //       <Toolbar>
  //         <IconButton
  //           size="large"
  //           edge="start"
  //           color="inherit"
  //           aria-label="menu"
  //           sx={{ mr: 2 }}
  //         >
  //           <MenuIcon />
  //         </IconButton>
  //         <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
  //           Sveiki, {Auth.user.attributes.email} - {getCurrentDate()}
  //           {loadingAlert()}
  //         </Typography>
  //         <Button variant="outlined" onClick={handleClickOpenOfRestorationEditor}>REDAGUOTI</Button>
  //         {<RestorationEditor />}
  //         <Button onClick={signOut} variant="contained">Atsijungti</Button>
  //       </Toolbar>
  //     </AppBar>
  //   );
  // }

  const [enableDatePicker, setEnableDatePicker] = React.useState(false);

  const loadSpecificOrder = (items) => {
    resetAlertState()
    resetOrderData()
    resetOrderDataOfCorrection()
    resetFileListArrayState()
    resetFileListArrayStateSTL()
    setSelectedOrderNumber(items.orderID)
    setNewOrderShow(false)

    // New state to track if orderReturnDate is empty
    const isOrderReturnDateEmpty = items.orderReturnDate === '';

    // Set the state to enable DatePicker and show the KOREGUOTI button
    setEnableDatePicker(isOrderReturnDateEmpty); // Assuming you have a state setter like this

    let tempObject = {}
    let implantNameArray
    let fileListArrayFromDB
    let fileListArrayFromDBSTL
    let fileListArrayFromDBOfCorrection
    let fileListArrayFromDBSTLOfCorrection

    let uniqueObjArray = [...new Map(historicOrderData.map((item) => [item["id"], item])).values()];

    // let sortedUniqueObjArray = uniqueObjArray.sort(
    //   (id1, id2) => (id1.orderID < id2.orderID) ? 1 : (id1.orderID > id2.orderID) ? -1 : 0);

    for (let i = 0; i < uniqueObjArray.length; i++) {
      if (uniqueObjArray[i].orderID.includes(items.orderID + "k")) {
        console.log("Correction order found!: ", uniqueObjArray[i].orderID)

        if (uniqueObjArray[i].fileListOfCorrection !== '') {
          let tempArray = uniqueObjArray[i].fileListOfCorrection.split(":")
          fileListArrayFromDBOfCorrection = tempArray
          console.log("Setting file list!: ", fileListArrayFromDBOfCorrection)
        } else {
          fileListArrayFromDBOfCorrection = []
        }

        if (uniqueObjArray[i].fileListSTLOfCorrection !== '') {
          let tempArray = uniqueObjArray[i].fileListSTLOfCorrection.split(":")
          fileListArrayFromDBSTLOfCorrection = tempArray

        } else {
          fileListArrayFromDBSTLOfCorrection = []
        }

        addDataObjectToArrayOfCorrection({
          selectedOrderCorrectionID: uniqueObjArray[i].orderID,
          selectedOrderCorrectionDate: uniqueObjArray[i].orderCorrectionDate,
          selectedOrderCorrectionReturnDate: uniqueObjArray[i].orderCorrectionReturnDate,
          selectedOrderCorrectionInformation: uniqueObjArray[i].orderCorrectionInformation,
          selectedFileListOfCorrection: fileListArrayFromDBOfCorrection,
          selectedFileListSTLOfCorrection: fileListArrayFromDBSTLOfCorrection,
        })
      }

      if (items.orderID == uniqueObjArray[i].orderID) {

        if (uniqueObjArray[i].implants !== '') {
          let tempArray = uniqueObjArray[i].implants.split(";")
          tempArray.forEach(arr => {
            let splitted = arr.split(":")
            tempObject[splitted[0]] = splitted[1]
          })

          implantNameArray = Object.keys(tempObject).map((key) => [Number(key), tempObject[key]]);

        } else {
          implantNameArray = []
        }

        if (uniqueObjArray[i].fileList !== '') {
          let tempArray = uniqueObjArray[i].fileList.split(":")

          fileListArrayFromDB = tempArray

        } else {
          fileListArrayFromDB = []
        }

        if (uniqueObjArray[i].fileListSTL !== '') {
          let tempArray = uniqueObjArray[i].fileListSTL.split(":")
          fileListArrayFromDBSTL = tempArray

        } else {
          fileListArrayFromDBSTL = []
        }

        addFileObjectToArraySTL({
          selectedFileList: fileListArrayFromDBSTL,
        })

        addFileObjectToArray({
          selectedFileList: fileListArrayFromDB,
        })

        addDataObjectToArray({
          selectedTeeth: uniqueObjArray[i].teeth.split(","),
          selectedTeethName: uniqueObjArray[i].teethName,
          selectedRestoration: uniqueObjArray[i].restoration.split(","),
          selectedImplant: implantNameArray,
          selectedColor: uniqueObjArray[i].color,
          selectedAdditionalInfo: uniqueObjArray[i].additionalInfo,
        })
      }
    }

    let parsedYear = items.orderReturnDate.split("/")[2],
      parsedMonth = items.orderReturnDate.split("/")[1] - 1,
      parsedDay = items.orderReturnDate.split("/")[0]

    let selectedOrderListOfPhotos = items.fileList.split(":")
    setListOfPhotos(selectedOrderListOfPhotos)

    setDoctor(items.doctor)
    setClinic(items.clinic)
    setClinicName(items.clinicName)
    let standardDate = new Date(parsedYear, parsedMonth, parsedDay, 1, 0)
    setValueAhead(standardDate)
    if (items.expedited == "true") {
      setExpeditedState({ expedited: true })
    } else {
      setExpeditedState({ expedited: false })
    }
    setPatientName(items.patient)
    setPatientDOB(items.patientDOB)
    setPatientGender(items.patientGender)
    setPatientAdditionalInfo(items.patientOtherInfo)
    setOrderState(items.orderState)
    setOrderLoaded(true)
    setOrderVersion(items._version)
  }

  return (

    <div>

      {user && dataLoaded ? (
        // User is authenticated

        < main >

          <ThemeProvider theme={themeOfMenu}>
            <AppBar sx={{ position: 'fixed' }}>
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={refreshPage}
                  sx={{ mr: 2 }}
                >
                  <RefreshIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Sveiki, {user.attributes.name} - {user.attributes.email} - {getCurrentDate()}
                </Typography>
                <Button variant="contained" color="success" onClick={newOrder}>Naujas užsakymas</Button>
                {(isAdmin.includes(userEmail)) ?
                  <Button onClick={handleClickOpenOfRestorationEditor} variant="contained" color="secondary" sx={{ ml: 2 }}>REDAGUOTI</Button>
                  : null}
                {<RestorationEditor />}
                {errorAlertDialogOpen ?
                  <ErrorAlertDialog />
                  : null}
                <Button onClick={handleSignOut} variant="contained" color="secondary" sx={{ ml: 2 }}>Atsijungti</Button>
              </Toolbar>
              {loadingAlert()}
              {creatingOrderAlert()}
              {deletingOrderAlert()}
            </AppBar>
            <Toolbar />
          </ThemeProvider>

          <div style={{ display: "none" }} // This make ComponentToPrint show   only while printing
          >
            <style>{pageStyle}</style>

            <ComponentToPrint ref={componentRef}
              expedited={expeditedState}
              orderID={selectedOrderNumber}
              clinicEmail={clinic}
              clinicName={clinicName}
              doctor={doctorChosen}
              orderReturnDate={(dayjs(valueAhead).format("DD/MM/YYYY"))}
              patientName={patientName}
              patientDOB={patientDOB}
              patientGender={patientGender}
              patientAdditionalInfo={patientAdditionalInfo}
              orderData={orderData}
              orderDataOfCorrection={orderDataOfCorrection}
            />
          </div>

          <div>

            <Box sx={{ flexGrow: 1, width: '100%' }}>
              <Grid container spacing={2}>

                {orderLoaded || newOrderShow ?
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    paddingTop={3}
                  >
                    <Grid item xs="auto">
                      {orderLoaded ?
                        <h2>Užsakymas nr.: {selectedOrderNumber} - {clinicName}</h2>
                        : <h2>Naujas užsakymas</h2>
                      }
                    </Grid>
                    <Grid item xs="auto">
                      {orderLoaded ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {orderState ? <h2>Būsena: {orderState}</h2> : null}
                          <ReactToPrint
                            pageStyle={pageStyle}
                            trigger={() => <Button variant="contained" sx={{ ml: 2 }}>PRINT</Button>}
                            content={() => componentRef.current}
                          />
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                  : null
                }

                {orderLoaded || newOrderShow ?
                  <Grid
                    container
                    height={100}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {orderLoaded ? null :
                      <Grid item xs="auto">
                        {(isAdmin.includes(userEmail)) ?
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="Vartotojas-field">Vartotojas</InputLabel>
                            <Select
                              disabled={loadingAlertState.loadingAlert}
                              readOnly={orderLoaded}
                              labelId="Vartotojas-field-autowidth-label"
                              id="Vartotojas-field-autowidth"
                              value={currentUserChosen}
                              onChange={handleChangeOfUser}
                              autoWidth
                              label="Vartotojas"
                            >
                              {currentUsers.map((user, index) => (
                                <MenuItem key={index} value={user.email}>{user.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          : null}
                      </Grid>
                    }
                    <Grid item xs="auto">
                      <FormControl sx={{ m: 0, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Gydytojas</InputLabel>
                        <Select
                          disabled={loadingAlertState.loadingAlert}
                          readOnly={orderLoaded}
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={doctorChosen}
                          onChange={handleChange}
                          autoWidth
                          label="Gydytojas"
                        >
                          {/* {doctors.map((doctor) => (
                            <MenuItem key={doctor.name} value={doctor.name}>{doctor.name}</MenuItem>
                          ))} */}

                          {doctors
                            .filter(doctor => !currentUserChosen || currentUserChosen === doctor.clinicID)
                            .map(doctor => (
                              <MenuItem key={doctor.name} value={doctor.name}>
                                {doctor.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs="auto" alignItems="center" paddingLeft={3}>
                      <Button variant="outlined"
                        onClick={isAdmin.includes(userEmail) ? handleAdminClickOpen : handleClickOpen}
                        disabled={orderLoaded}
                      >Pridėti naują
                      </Button>
                      <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Naujas gydytojas</DialogTitle>
                        <DialogContent>
                          <DialogContentText></DialogContentText>
                          {isAdmin.includes(userEmail) ? (
                            <>
                              {/* Render the dropdown menu for admins */}
                              <FormControl fullWidth>
                                <InputLabel id="Vartotojas-field">Vartotojas</InputLabel>
                                <Select
                                  disabled={loadingAlertState.loadingAlert}
                                  readOnly={orderLoaded}
                                  labelId="Vartotojas-field-autowidth-label"
                                  id="Vartotojas-field-autowidth"
                                  value={currentUserChosen}
                                  onChange={handleChangeOfUser}
                                  autoWidth
                                  label="Vartotojas"
                                >
                                  {currentUsers.map((user, index) => (
                                    <MenuItem key={index} value={user.email}>{user.name}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                          ) : null}
                          <TextField
                            onChange={saveNewDoctor}
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Vardas Pavardė"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={newDoctorValue}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Atšaukti</Button>
                          <Button onClick={addDoctorInput}>Pridėti</Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Grid>
                  : null}

                {orderLoaded || newOrderShow ?
                  <Grid
                    container
                    height={80}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs="auto">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="row" spacing={2} alignItems="center"> {/* Added Stack with horizontal direction and spacing */}
                          <DatePicker
                            views={['year', 'month', 'day']}
                            id="orderReturnTimeFrame"
                            label="Darbo atidavimo terminas"
                            inputFormat="DD/MM/YYYY"
                            value={valueAhead}
                            onChange={(newValueAhead) => {
                              setValueAhead(newValueAhead);
                              selectedDateTime(newValueAhead);
                              resetAlertState();
                            }}
                            minDate={nextNearestWorkingDay}
                            shouldDisableDate={checkIfWeekend}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                            readOnly={!((orderLoaded && enableDatePicker) || (!orderLoaded && newOrderShow))}
                          />
                          {orderLoaded && enableDatePicker && (
                            <Button
                              variant="contained"
                              onClick={() => updateOrderReturnDate(selectedOrderNumber, orderVersion, valueAhead)}
                            >
                              KOREGUOTI
                            </Button>
                          )}
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  : null}

                {orderLoaded || newOrderShow ?
                  <Grid
                    container
                    height={80}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs="auto">
                      <TextField
                        id="patientNameSurname"
                        label="Paciento Vardas Pavardė"
                        value={patientName}
                        onChange={handlePatientNameChange}
                        InputProps={{
                          readOnly: orderLoaded
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs="auto" alignItems="center" paddingLeft={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          key={'nameofpicker' + patientDOB}
                          id="patientDOB"
                          views={['year']}
                          openTo="year"
                          label="Gimimo metai"
                          minDate={dayjs('1930')}
                          maxDate={dayjs().subtract(10, 'year')}
                          value={patientDOB}
                          inputFormat="YYYY"
                          onChange={(dob) => {
                            let parseddob = `${dob['$D']}/${dob['$M'] < 10 ? `0${dob['$M']}` : `${dob['$M']}`}/${dob['$y']}`
                            let parsedYearOfdob = parseddob.split("/")[2]
                            setPatientDOB(parsedYearOfdob);
                            resetAlertState();
                          }}
                          renderInput={(params) => <TextField {...params} helperText={null} />}
                          readOnly={orderLoaded && !(userEmail === "mantas@me.com" || "info@3dsmile.lt")}
                        />
                      </LocalizationProvider>
                    </Grid> */}
                    <Grid item xs="auto" alignItems="center" paddingLeft={3}>
                      <FormControl>
                        <FormLabel id="patientGender">Lytis</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="patientGender"
                          value={patientGender}
                          onChange={handlePatientGenderChange}
                        >
                          <FormControlLabel value="female" control={<Radio />} label="Moteris" disabled={orderLoaded == true && patientGender == 'male' ? true : false} />
                          <FormControlLabel value="male" control={<Radio />} label="Vyras" disabled={orderLoaded == true && patientGender == 'female' ? true : false} />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  : null}

                {orderLoaded || newOrderShow ?
                  <Grid
                    container
                    height={100}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <FormControl fullWidth sx={{ m: 0 }}>
                        <TextField
                          id="otherPatientInfo"
                          maxRows="3"
                          label="Kita svarbi informacija apie pacientą"
                          placeholder=""
                          multiline
                          value={patientAdditionalInfo}
                          onChange={handlePatientAdditionalInfoChange}
                          InputProps={{
                            readOnly: orderLoaded
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  : null}

                {newOrderShow ?
                  <Grid
                    container
                    height={120}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    paddingBottom={2}
                  >
                    <Grid item xs="auto">
                      <FormControl component="fieldset" >
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="18"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t18 == true || orderLoaded} checked={checkedTeeth.t18} onChange={tHandleChange} name="t18" value="18" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="18"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="18x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t18x} onChange={teethBridgeHandleChange} name="t18x" value="18x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            value="17"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t17 == true || orderLoaded} checked={checkedTeeth.t17} onChange={tHandleChange} name="t17" value="17" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="17"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="17x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t17x} onChange={teethBridgeHandleChange} name="t17x" value="17x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            value="16"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t16 == true || orderLoaded} checked={checkedTeeth.t16} onChange={tHandleChange} name="t16" value="16" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="16"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="16x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t16x} onChange={teethBridgeHandleChange} name="t16x" value="16x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            value="15"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t15 == true || orderLoaded} checked={checkedTeeth.t15} onChange={tHandleChange} name="t15" value="15" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="15"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="15x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t15x} onChange={teethBridgeHandleChange} name="t15x" value="15x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="14"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t14 == true || orderLoaded} checked={checkedTeeth.t14} onChange={tHandleChange} name="t14" value="14" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="14"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="14x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t14x} onChange={teethBridgeHandleChange} name="t14x" value="14x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="13"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t13 == true || orderLoaded} checked={checkedTeeth.t13} onChange={tHandleChange} name="t13" value="13" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="13"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="13x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t13x} onChange={teethBridgeHandleChange} name="t13x" value="13x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="12"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t12 == true || orderLoaded} checked={checkedTeeth.t12} onChange={tHandleChange} name="t12" value="12" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="12"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="12x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t12x} onChange={teethBridgeHandleChange} name="t12x" value="12x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="11"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t11 == true || orderLoaded} checked={checkedTeeth.t11} onChange={tHandleChange} name="t11" value="11" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="11"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="11x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t11x} onChange={teethBridgeHandleChange} name="t11x" value="11x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />

                          {/* <Divider orientation="vertical" flexItem /> */}

                          <FormControlLabel
                            value="21"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t21 == true || orderLoaded} checked={checkedTeeth.t21} onChange={tHandleChange} name="t21" value="21" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="21"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="21x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t21x} onChange={teethBridgeHandleChange} name="t21x" value="21x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="22"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t22 == true || orderLoaded} checked={checkedTeeth.t22} onChange={tHandleChange} name="t22" value="22" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="22"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="22x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t22x} onChange={teethBridgeHandleChange} name="t22x" value="22x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="23"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t23 == true || orderLoaded} checked={checkedTeeth.t23} onChange={tHandleChange} name="t23" value="23" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="23"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="23x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t23x} onChange={teethBridgeHandleChange} name="t23x" value="23x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="24"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t24 == true || orderLoaded} checked={checkedTeeth.t24} onChange={tHandleChange} name="t24" value="24" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="24"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="24x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t24x} onChange={teethBridgeHandleChange} name="t24x" value="24x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="25"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t25 == true || orderLoaded} checked={checkedTeeth.t25} onChange={tHandleChange} name="t25" value="25" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="25"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="25x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t25x} onChange={teethBridgeHandleChange} name="t25x" value="25x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="26"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t26 == true || orderLoaded} checked={checkedTeeth.t26} onChange={tHandleChange} name="t26" value="26" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="26"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="26x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t26x} onChange={teethBridgeHandleChange} name="t26x" value="26x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="27"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t27 == true || orderLoaded} checked={checkedTeeth.t27} onChange={tHandleChange} name="t27" value="27" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="27"
                            labelPlacement="top"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="27x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t27x} onChange={teethBridgeHandleChange} name="t27x" value="27x" size="small" style={{ width: "0px", padding: 0 }} />}
                            labelPlacement="top"
                            label="<>"
                          />
                          <FormControlLabel
                            value="28"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t28 == true || orderLoaded} checked={checkedTeeth.t28} onChange={tHandleChange} name="t28" value="28" style={{ width: "0px", paddingBottom: 20 }} />}
                            label="28"
                            labelPlacement="top"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  : null}

                {newOrderShow ?
                  <Grid
                    container
                    minHeight={0}
                    direction="row"
                    justifyContent="left"
                    alignItems="center"
                    paddingBottom={3}
                  >
                    <Grid>
                      {errorAlert()}
                    </Grid>
                  </Grid>
                  : null}

                {newOrderShow ?
                  <Grid container spacing={2} justifyContent="center">
                    {/*They all will have default widths */}
                    <Grid item xs={3} >
                      <Autocomplete sx={{ width: 300 }}
                        options={restoration}
                        id="restoration"
                        value={restorationName == null ? restorationName : restorationName[0]}
                        onChange={(event, newValue) => { restorationOnChange(newValue) }}

                        renderInput={(params) => (
                          <TextField {...params} label="Restauracija" />
                        )}
                        disabled={orderLoaded}
                      />
                    </Grid>
                    <Grid item xs={3} justifyContent="center" alignItems="center">
                      <Grid container spacing={2} justifyContent="center">
                        {implantFieldsEnabled ? <ImplantsList /> : null}
                      </Grid>
                    </Grid>
                    <Grid item xs={1} >
                      <FormControl sx={{ minWidth: 100 }}>
                        <InputLabel id="color">Spalva</InputLabel>
                        <Select
                          labelId="color"
                          id="color"
                          value={colorName}
                          onChange={colorHandleChange}
                          autoWidth
                          label="Spalva"
                          disabled={orderLoaded}
                        >
                          {color.map((color) => (
                            <MenuItem
                              key={color}
                              value={color}
                            >
                              {color}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} >

                      <Button variant="contained" color="success" aria-label="Įkelti nuotraukas" component="label" sx={{ m: 1 }} disabled={fileLimit || orderLoaded} >
                        <input hidden
                          id="fileUpload"
                          type="file"
                          multiple
                          accept=".png, .jpg, .jpeg, .heic"
                          onChange={handleFileEvent}
                          disabled={fileLimit || creatingOrderAlertState}
                        />
                        ĮKELTI NUOTRAUKAS
                      </Button>

                      <ul>{listSelectedFiles}</ul>
                    </Grid>
                    <Grid item xs={2} >
                      <Button variant="contained" color="success" aria-label="Įkelti STL faila" component="label" sx={{ m: 1 }} disabled={fileLimitSTL || orderLoaded}>
                        <input hidden
                          id="fileUploadSTL"
                          type="file"
                          multiple
                          accept=".stl"
                          onChange={handleFileEventSTL}
                          disabled={fileLimitSTL || creatingOrderAlertState}
                        />
                        ĮKELTI STL
                      </Button>

                      <ul>{listSelectedFilesSTL}</ul>
                    </Grid>
                  </Grid>
                  : null}

                <Grid
                  container
                  minHeight={0}

                >
                </Grid>

                {newOrderShow ?
                  <Grid container spacing={2}>
                    <Grid item xs={7} >
                      <Grid item xs={11}>
                        <FormControl sx={{ width: 600 }}>
                          <TextField
                            required
                            id="additionalInfo"
                            maxRows="3"
                            label="Papildoma informacija"
                            placeholder=""
                            multiline
                            value={additionalInfoValue}
                            onChange={additionalInfoHandleChange}
                            disabled={orderLoaded}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={3} >

                    </Grid>
                    <Grid item xs={2} >

                    </Grid>
                  </Grid>
                  : null}

                {newOrderShow ?
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid item xs="auto">
                      <FormControl sx={{ m: 2 }}>
                        <Button variant="contained" color="success" onClick={addOrder} disabled={creatingOrderAlertState}>
                          PRIDĖTI Į UŽSAKYMĄ
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                  : null}

                {newOrderShow ?
                  <Grid
                    container
                    height={120}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    paddingBottom={2}
                  >
                    <Grid item xs="auto">
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="48"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t48 == true || orderLoaded} checked={checkedTeeth.t48} onChange={tHandleChange} name="t48" value="48" style={{ width: "0px", paddingTop: 20 }} />}
                            label="48"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="48x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t48x} onChange={teethBridgeHandleChange} name="t48x" value="48x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="47"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t47 == true || orderLoaded} checked={checkedTeeth.t47} onChange={tHandleChange} name="t47" value="47" style={{ width: "0px", paddingTop: 20 }} />}
                            label="47"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="47x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t47x} onChange={teethBridgeHandleChange} name="t47x" value="47x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="46"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t46 == true || orderLoaded} checked={checkedTeeth.t46} onChange={tHandleChange} name="t46" value="46" style={{ width: "0px", paddingTop: 20 }} />}
                            label="46"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="46x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t46x} onChange={teethBridgeHandleChange} name="t46x" value="46x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="45"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t45 == true || orderLoaded} checked={checkedTeeth.t45} onChange={tHandleChange} name="t45" value="45" style={{ width: "0px", paddingTop: 20 }} />}
                            label="45"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="45x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t45x} onChange={teethBridgeHandleChange} name="t45x" value="45x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="44"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t44 == true || orderLoaded} checked={checkedTeeth.t44} onChange={tHandleChange} name="t44" value="44" style={{ width: "0px", paddingTop: 20 }} />}
                            label="44"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="44x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t44x} onChange={teethBridgeHandleChange} name="t44x" value="44x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="43"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t43 == true || orderLoaded} checked={checkedTeeth.t43} onChange={tHandleChange} name="t43" value="43" style={{ width: "0px", paddingTop: 20 }} />}
                            label="43"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="43x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t43x} onChange={teethBridgeHandleChange} name="t43x" value="43x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="42"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t42 == true || orderLoaded} checked={checkedTeeth.t42} onChange={tHandleChange} name="t42" value="42" style={{ width: "0px", paddingTop: 20 }} />}
                            label="42"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="42x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t42x} onChange={teethBridgeHandleChange} name="t42x" value="42x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="41"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t41 == true || orderLoaded} checked={checkedTeeth.t41} onChange={tHandleChange} name="t41" value="41" style={{ width: "0px", paddingTop: 20 }} />}
                            label="41"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="41x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t41x} onChange={teethBridgeHandleChange} name="t41x" value="41x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />

                          {/* <Divider orientation="vertical" flexItem /> */}

                          <FormControlLabel
                            value="31"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t31 == true || orderLoaded} checked={checkedTeeth.t31} onChange={tHandleChange} name="t31" value="31" style={{ width: "0px", paddingTop: 20 }} />}
                            label="31"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="31x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t31x} onChange={teethBridgeHandleChange} name="t31x" value="31x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="32"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t32 == true || orderLoaded} checked={checkedTeeth.t32} onChange={tHandleChange} name="t32" value="32" style={{ width: "0px", paddingTop: 20 }} />}
                            label="32"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="32x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t32x} onChange={teethBridgeHandleChange} name="t32x" value="32x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="33"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t33 == true || orderLoaded} checked={checkedTeeth.t33} onChange={tHandleChange} name="t33" value="33" style={{ width: "0px", paddingTop: 20 }} />}
                            label="33"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="33x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t33x} onChange={teethBridgeHandleChange} name="t33x" value="33x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="34"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t34 == true || orderLoaded} checked={checkedTeeth.t34} onChange={tHandleChange} name="t34" value="34" style={{ width: "0px", paddingTop: 20 }} />}
                            label="34"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="34x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t34x} onChange={teethBridgeHandleChange} name="t34x" value="34x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="35"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t35 == true || orderLoaded} checked={checkedTeeth.t35} onChange={tHandleChange} name="t35" value="35" style={{ width: "0px", paddingTop: 20 }} />}
                            label="35"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="35x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t35x} onChange={teethBridgeHandleChange} name="t35x" value="35x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="36"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t36 == true || orderLoaded} checked={checkedTeeth.t36} onChange={tHandleChange} name="t36" value="36" style={{ width: "0px", paddingTop: 20 }} />}
                            label="36"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="36x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t36x} onChange={teethBridgeHandleChange} name="t36x" value="36x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="37"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t37 == true || orderLoaded} checked={checkedTeeth.t37} onChange={tHandleChange} name="t37" value="37" style={{ width: "0px", paddingTop: 20 }} />}
                            label="37"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel style={styles.bridgeCheckbox}
                            value="37x"
                            control={<Checkbox disabled={orderLoaded} checked={checkedTeethBridge.t37x} onChange={teethBridgeHandleChange} name="t37x" value="37x" size="small" style={{ width: "0px", padding: 0 }} />}
                            label="<>"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="38"
                            control={<Checkbox disabled={checkedTeethInTheBridge.t38 == true || orderLoaded} checked={checkedTeeth.t38} onChange={tHandleChange} name="t38" value="38" style={{ width: "0px", paddingTop: 20 }} />}
                            label="38"
                            labelPlacement="bottom"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  : null}

                {orderLoaded || newOrderShow ?
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <TableContainer component={Paper}>
                        <Table sx={{}} aria-label="simple table">
                          <TableHead>
                            <TableRow key="random"
                              sx={{
                                "& th": {
                                  color: "rgba(96, 96, 96)",
                                  backgroundColor: "whitesmoke"
                                }
                              }}
                            >
                              <TableCell>Dantis / Dantys</TableCell>
                              <TableCell align="left">Restauracija</TableCell>
                              <TableCell align="left">Implantai</TableCell>
                              <TableCell align="left">Spalva</TableCell>
                              <TableCell align="left">Papildoma informacija</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderData?.map((items, i) => (
                              <TableRow
                                key={i + items}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">{items.selectedTeeth?.map((line, index) =>
                                  index == 0 ? <span key={index + line}>{line}</span> : <span key={index + line}> / {line}</span>
                                )}
                                </TableCell>
                                <TableCell component="th" scope="row">{items.selectedRestoration?.map((line, index) => <p key={index + line}>{line}</p>)}</TableCell>
                                <TableCell component="th" scope="row">{items.selectedImplant?.map((line, index) =>
                                  <div key={index + line}>
                                    {line.map((teethnumber, teethnumberindex) =>
                                      teethnumberindex == 0 ? <span key={teethnumberindex + teethnumber}>{teethnumber}</span> : <span key={teethnumberindex + teethnumber}>: {teethnumber}</span>

                                    )}
                                  </div>
                                )}</TableCell>
                                <TableCell component="th" scope="row">{items.selectedColor}</TableCell>
                                <TableCell component="th" scope="row">{items.selectedAdditionalInfo}</TableCell>

                                {orderLoaded ? null :
                                  <TableCell component="th" scope="row">
                                    <Button key="Trinti" value={i} variant="contained" color="error" size="small" onClick={deleteFromPreOrder}>
                                      TRINTI
                                    </Button></TableCell>
                                }
                              </TableRow>
                            ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={4}>
                      {orderLoaded ?
                        <TableContainer component={Paper} key="other">
                          <Table sx={{}} aria-label="simple table">
                            <TableHead>
                              <TableRow key="random"
                                sx={{
                                  "& th": {
                                    color: "rgba(96, 96, 96)",
                                    backgroundColor: "whitesmoke"
                                  }
                                }}
                              >
                                <TableCell align="left">Nuotraukos</TableCell>
                                <TableCell align="left">STL failai</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >

                                <Counter />

                                <TableCell component="th" scope="row" align="left">{fileListArraySTL?.map((files, i) =>

                                  files.selectedFileList?.map((line, index) =>
                                    i == 0 ?
                                      <div key={index + line + "STL_files_div"}>
                                        <span key={index + line + "STL_files"}><a href={"https://files114336-dev.s3.eu-central-1.amazonaws.com/public/" + selectedOrderNumber + "_" + line} rel="noopener">{line}</a></span>
                                      </div>
                                      : null
                                  ))
                                }
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        : null}
                    </Grid>
                  </Grid>
                  : null}

                {newOrderShow ?
                  <Grid
                    container
                    height={100}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid>
                      <Button variant="contained" color="success" onClick={createOrder} disabled={creatingOrderAlertState}>
                        UŽSAKYTI
                      </Button>
                    </Grid>

                  </Grid>
                  : null}

                {orderLoaded ?
                  <Grid container spacing={2}>
                    <Grid item xs="auto" >
                      <h2>Užsakymo korekcijos</h2>
                    </Grid>
                  </Grid>
                  : null
                }

                {orderLoaded ?
                  <Grid
                    container
                    minHeight={0}
                    direction="row"
                    justifyContent="left"
                    alignItems="center"
                    paddingBottom={3}
                  >
                    <Grid>
                      {errorAlert()}
                    </Grid>
                  </Grid>
                  : null
                }

                {orderDataOfCorrection?.[0] ?
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TableContainer component={Paper}>
                        <Table sx={{}} aria-label="simple table">
                          <TableHead>
                            <TableRow key="random"
                              sx={{
                                "& th": {
                                  color: "rgba(96, 96, 96)",
                                  backgroundColor: "whitesmoke"
                                }
                              }}
                            >
                              <TableCell align="left">Korekcijos nr.</TableCell>
                              <TableCell align="left">Korekcijos data</TableCell>
                              <TableCell align="left">Atidavimo data</TableCell>
                              <TableCell align="left">Korekcijos informacija</TableCell>
                              <TableCell align="left">Nuotraukos</TableCell>
                              <TableCell align="left">STL failai</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderDataOfCorrection?.map((items, i) => (
                              <TableRow
                                key={i + items}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">{items.selectedOrderCorrectionID}</TableCell>
                                <TableCell component="th" scope="row">{items.selectedOrderCorrectionDate}</TableCell>
                                <TableCell component="th" scope="row">{items.selectedOrderCorrectionReturnDate}</TableCell>
                                <TableCell component="th" scope="row">{items.selectedOrderCorrectionInformation}</TableCell>
                                <TableCell component="th" scope="row" align="left">{items.selectedFileListOfCorrection?.map((line, index) =>
                                  <div key={index} align="left">
                                  </div>
                                )}
                                  <PhotoProvider>
                                    <div className="foo">
                                      {items.selectedFileListOfCorrection.map((item, index) => (
                                        <PhotoView key={index} src={"https://files114336-dev.s3.eu-central-1.amazonaws.com/public/" + items.selectedOrderCorrectionID + "_" + item}>
                                          <span key={index + item + "Correction_files"}><button className="link-button" onClick={() => setVisible(true)}>{item}</button><br /></span>
                                        </PhotoView>
                                      ))}
                                    </div>
                                  </PhotoProvider>
                                </TableCell>

                                <TableCell component="th" scope="row" align="left">{items.selectedFileListSTLOfCorrection?.map((line, index) =>
                                  <div key={index + line + "Correction_STL_files_div"}>
                                    <span key={index + line + "Correction_STL_files"}><a href={"https://files114336-dev.s3.eu-central-1.amazonaws.com/public/" + items.selectedOrderCorrectionID + "_" + line} rel="noopener">{line}</a></span>
                                  </div>
                                )}
                                </TableCell>

                              </TableRow>
                            ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  : null
                }

                {orderLoaded ?

                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    paddingTop={3}
                  >
                    <Grid item xs="auto">
                      <Grid item xs="auto">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            id="orderCorrectionReturnTimeFrame"
                            label="Darbo atidavimo terminas"
                            inputFormat="DD/MM/YYYY"
                            value={orderCorrectionReturnDate}
                            onChange={(newOrderCorrectionReturnDate) => {
                              setOrderCorrectionReturnDate(newOrderCorrectionReturnDate);
                              selectedDateTimeOfOrderCorrection(newOrderCorrectionReturnDate);
                            }}
                            minDate={nextNearestWorkingDay}
                            shouldDisableDate={checkIfWeekend}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid item xs="auto" alignItems="center" paddingLeft={3}>
                      <FormControl sx={{ width: 490 }}>
                        <TextField
                          required
                          id="additionalInfo"
                          maxRows="4"
                          label="Korekcijos informacija"
                          placeholder=""
                          multiline
                          value={additionalInfoValueOfOrderCorrection}
                          onChange={additionalInfoHandleChangeOfOrderCorrection}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} >
                      <Button variant="contained" color="success" aria-label="Įkelti nuotraukas" component="label" sx={{ m: 1 }} disabled={fileLimit} >
                        <input hidden
                          id="fileUploadOfOrderCorrection"
                          type="file"
                          multiple
                          accept=".png, .jpg, .jpeg, .heic"
                          onChange={handleFileEventOfCorrection}
                          disabled={fileLimit || creatingOrderAlertState}
                        />
                        ĮKELTI NUOTRAUKAS
                      </Button>
                      <ul>{listSelectedFilesOfCorrection}</ul>
                    </Grid>
                    <Grid item xs={2} >
                      <Button variant="contained" color="success" aria-label="Įkelti STL faila" component="label" sx={{ m: 1 }} disabled={fileLimitSTL}>
                        <input hidden
                          id="fileUploadSTLOfOrderCorrection"
                          type="file"
                          multiple
                          accept=".stl"
                          onChange={handleFileEventSTLOfCorrection}
                          disabled={fileLimitSTL || creatingOrderAlertState}
                        />
                        ĮKELTI STL
                      </Button>

                      <ul>{listSelectedFilesSTLOfCorrection}</ul>
                    </Grid>
                  </Grid>
                  : null
                }

                {orderLoaded ?
                  <Grid
                    container
                    height={50}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid>
                      <Button variant="contained" color="success" onClick={createCorrection}>
                        Registruoti korekciją
                      </Button>
                    </Grid>
                  </Grid>
                  : null
                }

                {newOrderShow ? null
                  :
                  <Grid
                    container
                    height={120}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={10} >
                      <h2>Užsakymų istorija</h2>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 680 }} aria-label="simple table">
                          <TableHead>
                            <TableRow key="random"
                              sx={{
                                "& th": {
                                  color: "rgba(96, 96, 96)",
                                  backgroundColor: "mistyrose",
                                  fontWeight: "bold",
                                  paddingBottom: "20px"
                                }
                              }}>
                              <TableCell width="4%" style={styles.tableHeaders}>Nr.</TableCell>
                              <TableCell width="12%" style={styles.tableHeaders}><input
                                type="text"
                                placeholder="Klinika"
                                value={clinicFilter}
                                onChange={(e) => setClinicFilter(e.target.value)}
                                style={{ width: '80px' }} // Set the width to your desired value
                              /></TableCell>
                              <TableCell width="21%" style={styles.tableHeaders}>Gydytojas</TableCell>
                              <TableCell width="18%" style={styles.tableHeaders}><input
                                type="text"
                                placeholder="Klientas"
                                value={clientFilter}
                                onChange={(e) => setClientFilter(e.target.value)}
                                style={{ width: '80px' }} // Set the width to your desired value
                              /></TableCell>
                              <TableCell width="9%" style={styles.tableHeaders}>Užsakymo data</TableCell>
                              <TableCell width="9%" style={styles.tableHeaders}>Užsakymo atidavimo data
                                <select
                                  value={selectedMonth}
                                  onChange={(e) => setSelectedMonth(e.target.value)}
                                >
                                  <option value="VISI">Visi</option>
                                  <option value="01">Sausis</option>
                                  <option value="02">Vasaris</option>
                                  <option value="03">Kovas</option>
                                  <option value="04">Balandis</option>
                                  <option value="05">Gegužė</option>
                                  <option value="06">Birželis</option>
                                  <option value="07">Liepa</option>
                                  <option value="08">Rugpjūtis</option>
                                  <option value="09">Rugsėjis</option>
                                  <option value="10">Spalis</option>
                                  <option value="11">Lapkritis</option>
                                  <option value="12">Gruodis</option>
                                  {/* Add options for all months */}
                                </select>
                              </TableCell>
                              <TableCell width="9%" style={styles.tableHeaders}>Būsena</TableCell>
                              <TableCell width="18%" style={styles.tableHeaders}></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {historicOrderData
                              .reduce((uniqueOrders, item) => {
                                const index = uniqueOrders.findIndex((uniqueItem) => uniqueItem.orderID === item.orderID);

                                if (index === -1) {
                                  uniqueOrders.push(item);
                                } else {
                                  // Compare based on _lastChangedAt to prioritize the item with the highest value
                                  if (item._lastChangedAt > uniqueOrders[index]._lastChangedAt) {
                                    uniqueOrders[index] = item;
                                  }
                                }

                                return uniqueOrders;
                              }, [])
                              .filter((item) =>
                                !item.orderID.includes("k") &&
                                item.patient.toLowerCase().includes(clientFilter.toLowerCase()) &&
                                item.clinicName.toLowerCase().includes(clinicFilter.toLowerCase()) &&
                                (selectedMonth === "VISI" || item.orderReturnDate === '' || (/\/(\d{2})\//.test(item.orderReturnDate) && RegExp.$1 === selectedMonth))
                              )
                              .sort((a, b) => {
                                const stateA = a.orderState;
                                const stateB = b.orderState;

                                // Prioritize Užsakyta first
                                if (stateA === 'Užsakyta' && stateB !== 'Užsakyta') return -1;
                                if (stateB === 'Užsakyta' && stateA !== 'Užsakyta') return 1;

                                // Next, prioritize Gaminama
                                if (stateA === 'Gaminama' && stateB !== 'Gaminama') return -1;
                                if (stateB === 'Gaminama' && stateA !== 'Gaminama') return 1;

                                // Sort by date if states are the same or both are 'Pagaminta'
                                const dateA = convertDateToSortable(a.orderReturnDate);
                                const dateB = convertDateToSortable(b.orderReturnDate);

                                if (dateA < dateB) return -1;
                                if (dateA > dateB) return 1;
                                return 0;
                              })
                              .map((item, index) => (
                                <TableRow
                                  hover
                                  key={item.id} // Use a unique key, assuming 'id' is unique
                                  sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    backgroundColor: item.orderState === "Užsakyta" ? 'pink' : 'transparent',
                                  }}
                                  onClick={() => loadSpecificOrder(item)}
                                >
                                  <TableCell component="th" scope="row" width="4%" style={styles.tableContent}>{item.orderID}</TableCell>
                                  <TableCell component="th" scope="row" width="13%" style={styles.tableContent}>{item.clinicName}</TableCell>
                                  <TableCell component="th" scope="row" width="22%" style={styles.tableContent}>{item.doctor}</TableCell>
                                  <TableCell component="th" scope="row" width="16%" style={styles.tableContent}>{item.patient}</TableCell>
                                  <TableCell component="th" scope="row" width="9%" style={styles.tableContent}>{item.orderDate}</TableCell>
                                  <TableCell component="th" scope="row" width="9%" style={styles.tableContent}>
                                    {item.orderReturnDate === '' ? 'NEPASIRINKTA' : item.orderReturnDate}
                                  </TableCell>
                                  <TableCell component="th" scope="row" width="9%" style={styles.tableContent}>{item.orderState}</TableCell>
                                  <TableCell component="th" scope="row" width="18%" style={styles.tableContent}>

                                    {(isAdmin.includes(userEmail)) ?
                                      <Stack direction="row" spacing={2}>
                                        {item.orderState == "Užsakyta" ?
                                          <YellowButton variant="contained" onClick={(e) => {
                                            e.stopPropagation(); // Stop propagation of the click event
                                            changeOrderState(item, "Gaminama")
                                          }
                                          }>
                                            GAMINAMA
                                          </YellowButton>
                                          : null}

                                        {item.orderState == "Gaminama" ?
                                          <Button variant="contained" color="success" onClick={(e) => {
                                            e.stopPropagation(); // Stop propagation of the click event
                                            changeOrderState(item, "Pagaminta")
                                          }
                                          }>
                                            PAGAMINTA
                                          </Button>
                                          : null}

                                        <Button variant="contained" color="error" margin="10px" onClick={(e) => {
                                          e.stopPropagation(); // Stop propagation of the click event
                                          handleClickOpenOfDeleteOrder(item);
                                        }}>
                                          TRINTI
                                        </Button>
                                      </Stack>
                                      : null
                                    }

                                  </TableCell>

                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Dialog open={openOfDeleteOrder} onClose={handleCloseOfDeleteOrder}>
                        <DialogTitle>IŠTRINAMAS UŽSAKYMAS {selectedOrderItems?.orderID}</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Ar tikrai norite ištrinti užsakymą <b>{selectedOrderItems?.orderID}</b> ir kartu su juo susijusius korekcijos užsakymus?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={(e) => {
                            e.stopPropagation(); // Stop propagation of the click event
                            handleCloseOfDeleteOrder()
                          }}>
                            ATŠAUKTI</Button>
                          <Button onClick={(e) => {
                            e.stopPropagation(); // Stop propagation of the click event
                            deleteOrderFromAllOrdersInput(selectedOrderItems)
                          }}>
                            TRINTI</Button>
                        </DialogActions>
                      </Dialog>

                    </Grid>
                  </Grid>
                }

              </Grid>
            </Box>

          </div>

        </main>

      ) : user ? (
        // User is authenticated but action is not completed yet, display a loading message
        <p><br /><br /><br />Loading...</p>
      ) : (
        // User is not authenticated, display login screen or components
        <p>Not logged in</p>

      )}
    </div >
  );
}

export default Home;