/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDoctor = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      name
      clinicID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDoctors = /* GraphQL */ `
  query ListDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        clinicID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDoctors = /* GraphQL */ `
  query SyncDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        clinicID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRestorations = /* GraphQL */ `
  query GetRestorations($id: ID!) {
    getRestorations(id: $id) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRestorations = /* GraphQL */ `
  query ListRestorations(
    $filter: ModelRestorationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestorations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRestorations = /* GraphQL */ `
  query SyncRestorations(
    $filter: ModelRestorationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRestorations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAllOrders = /* GraphQL */ `
  query GetAllOrders($id: ID!) {
    getAllOrders(id: $id) {
      id
      orderID
      clinic
      doctor
      clinicName
      patient
      patientDOB
      patientGender
      patientOtherInfo
      orderDate
      orderReturnDate
      teeth
      teethName
      restoration
      implants
      color
      additionalInfo
      orderState
      expedited
      fileList
      fileListSTL
      orderCorrectionDate
      orderCorrectionReturnDate
      orderCorrectionInformation
      fileListOfCorrection
      fileListSTLOfCorrection
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAllOrders = /* GraphQL */ `
  query ListAllOrders(
    $filter: ModelAllOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderID
        clinic
        doctor
        clinicName
        patient
        patientDOB
        patientGender
        patientOtherInfo
        orderDate
        orderReturnDate
        teeth
        teethName
        restoration
        implants
        color
        additionalInfo
        orderState
        expedited
        fileList
        fileListSTL
        orderCorrectionDate
        orderCorrectionReturnDate
        orderCorrectionInformation
        fileListOfCorrection
        fileListSTLOfCorrection
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAllOrders = /* GraphQL */ `
  query SyncAllOrders(
    $filter: ModelAllOrdersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAllOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderID
        clinic
        doctor
        clinicName
        patient
        patientDOB
        patientGender
        patientOtherInfo
        orderDate
        orderReturnDate
        teeth
        teethName
        restoration
        implants
        color
        additionalInfo
        orderState
        expedited
        fileList
        fileListSTL
        orderCorrectionDate
        orderCorrectionReturnDate
        orderCorrectionInformation
        fileListOfCorrection
        fileListSTLOfCorrection
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrderNumber = /* GraphQL */ `
  query GetOrderNumber($id: ID!) {
    getOrderNumber(id: $id) {
      id
      orderNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOrderNumbers = /* GraphQL */ `
  query ListOrderNumbers(
    $filter: ModelOrderNumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrderNumbers = /* GraphQL */ `
  query SyncOrderNumbers(
    $filter: ModelOrderNumberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderNumbers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const doctorByClinic = /* GraphQL */ `
  query DoctorByClinic(
    $clinicID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByClinic(
      clinicID: $clinicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        clinicID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const orderByOrder = /* GraphQL */ `
  query OrderByOrder(
    $orderID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAllOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByOrder(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        clinic
        doctor
        clinicName
        patient
        patientDOB
        patientGender
        patientOtherInfo
        orderDate
        orderReturnDate
        teeth
        teethName
        restoration
        implants
        color
        additionalInfo
        orderState
        expedited
        fileList
        fileListSTL
        orderCorrectionDate
        orderCorrectionReturnDate
        orderCorrectionInformation
        fileListOfCorrection
        fileListSTLOfCorrection
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
