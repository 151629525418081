import './App.css';
import React from 'react'
import { Amplify, I18n } from 'aws-amplify'
import { Authenticator, Text, View, useTheme, Image } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
import 'react-photo-view/dist/react-photo-view.css';
import logo from './logo.png';
import Home from './Home';

// Set the log level before configuring Amplify
//Amplify.Logger.LOG_LEVEL = "DEBUG";

Amplify.configure(awsExports);

const formFields = {
  signIn: {
    username: {
      placeholder: 'Įveskite email adresą',
      isRequired: true,
      label: 'Email:'
    },
    password: {
      placeholder: 'Įveskite slaptažodį',
      isRequired: true,
      label: 'Slaptažodis:'
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="3D Smile"
          src={logo}
          height="200px"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
};

I18n.putVocabulariesForLanguage('en', {
  'Sign In': 'Prisijungti', // Tab header
  'Sign in': 'Prisijungti', // Button label
  'Sign in to your account': 'Welcome Back!',
  Username: 'Enter your username', // Username label
  Password: 'Slaptažodis', // Password label
  'Forgot your password?': 'Pamiršau slaptažodį',
});

const styles = {
  container: { width: 1366, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
}

function App() {
  return (
    <Authenticator.Provider>
      <Authenticator
        formFields={formFields}
        components={components}
        hideSignUp={true}
      >
        <View style={styles.container}>
          <Home />
        </View>
      </Authenticator>
    </Authenticator.Provider>
  );
}

export default App;